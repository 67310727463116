
[data-novi-id="8"]{
  font-size: 15px;
}
[data-novi-id="7"]{
  font-size: 15px;
}
[data-novi-id="5"]{
  text-align: center;
}
[data-novi-id="4"]{
  text-align: center;
}
[data-novi-id="3"]{
  text-align: center;
}
[data-novi-id="2"]{
  color: #3e4555;
  color: #188ef4;
}
[data-novi-id="1"]{
  color: #188ef4;

  text-align: center;
}
[data-novi-id="0"]{
  color: #316ce8;

  color: #ad0928;

  
  text-align: center;
  color: #019e7d;
  
}