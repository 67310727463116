/*
Template Name: Wrakit UI Kit
Author: wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/**
 * Table Of Content
 *
 *  1. Globals
 *  2. Headers
 *  3. Navigations
 *  4. Banners
 *  5. Footers
 *  6. app
 *  7. Widgets
 *  8. Custom Templates
 */
/*
Template Name: Wrapkit
Author: wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*Theme Colors*/
/*bootstrap Color*/
/*Light colors*/
/*Normal Color*/
/*Extra Variable*/
/*Gradiant*/
@import url(animate.css);
@import url(icons/font-awesome/css/font-awesome.min.css);
@import url(icons/simple-line-icons/css/simple-line-icons.css);
@import url(icons/weather-icons/css/weather-icons.min.css);
@import url(icons/themify-icons/themify-icons.css);
@import url(icons/iconmind/iconmind.css);
/*
Template Name: Wrakit UI Kit
Author: wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*******************
Preloader
********************/
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Rubik:400,400i,500,500i,700,700i");
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Playfair+Display:700,700i");

.preloader {
  width: 100%;
  height: 100%;
  top: 0px;
  position: fixed;
  z-index: 99999;
  background: #fff;
}

.preloader .cssload-speeding-wheel {
  position: absolute;
  top: calc(50% - 3.5px);
  left: calc(50% - 3.5px);
}

.loader,
.loader__figure {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loader {
  overflow: visible;
  padding-top: 2em;
  height: 0;
  width: 2em;
}

.loader__figure {
  height: 0;
  width: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 0 solid #019e7d;
  border-radius: 50%;
  -webkit-animation: loader-figure 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-animation: loader-figure 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: loader-figure 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}

.loader__label {
  float: left;
  margin-left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  margin: 0.5em 0 0 50%;
  font-size: 0.875em;
  letter-spacing: 0.1em;
  line-height: 1.5em;
  color: #019e7d;
  white-space: nowrap;
  -webkit-animation: loader-label 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-animation: loader-label 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: loader-label 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}

@-webkit-keyframes loader-figure {
  0% {
    height: 0;
    width: 0;
    background-color: #019e7d;
  }

  29% {
    background-color: #019e7d;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-moz-keyframes loader-figure {
  0% {
    height: 0;
    width: 0;
    background-color: #019e7d;
  }

  29% {
    background-color: #019e7d;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@keyframes loader-figure {
  0% {
    height: 0;
    width: 0;
    background-color: #019e7d;
  }

  29% {
    background-color: #019e7d;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-webkit-keyframes loader-label {
  0% {
    opacity: 0.25;
  }

  30% {
    opacity: 1;
  }

  100% {
    opacity: 0.25;
  }
}

@-moz-keyframes loader-label {
  0% {
    opacity: 0.25;
  }

  30% {
    opacity: 1;
  }

  100% {
    opacity: 0.25;
  }
}

@keyframes loader-label {
  0% {
    opacity: 0.25;
  }

  30% {
    opacity: 1;
  }

  100% {
    opacity: 0.25;
  }
}

.landing-page {
  /*******************
  topbar color
  *******************/
  /*******************
  Static slide 10
  *******************/
  /*******************
  Counter box
  *******************/
  /*******************
  Testimonial 3
  *******************/
  /*******************
  Blog homepage 1
  *******************/
  /*******************
  Responsive
  *******************/
}

.landing-page .topbar {
  position: fixed;
}

.landing-page .topbar .header6 {
  background-color: transparent;
}

.landing-page .topbar.fixed-header .header6 {
  background-color: #263238;
}

.landing-page .topbar .h6-nav-bar .navbar-nav .nav-item:hover .nav-link,
.landing-page .topbar .h6-nav-bar .navbar-nav .nav-item.active .nav-link {
  color: #ffffff;
}

.landing-page .static-slider10 {
  min-height: calc(100vh - 90px);
  padding: 5% 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: auto;
  background-size: cover;
  background-position: center center;
}

.landing-page .static-slider10 h1 {
  color: #ffffff;
  font-weight: 700;
  font-size: 100px;
  line-height: 150px;
}

.landing-page .static-slider10 .subtitle {
  color: #ffffff;
  line-height: 30px;
}

.landing-page .counter-box [class^=col-] {
  padding: 33px 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.landing-page .counter-box [class^=col-]:last-child {
  border-right: 0px;
}

.landing-page .counter-box-dark [class^=col-] {
  border-right: 1px solid rgba(120, 130, 140, 0.13);
}

.landing-page .counter-box-dark [class^=col-]:last-child {
  border-right: 0px;
}

.landing-page .testimonial3 .testi3 .card-body {
  padding: 40px;
}

.landing-page .testimonial3 .testi3 h6 {
  line-height: 26px;
}

.landing-page .testimonial3 .testi3 .thumb-img img {
  width: 60px;
}

.landing-page .testimonial3 .testi3 .customer {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}

.landing-page .blog-home1 [class*=col-] {
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.landing-page .blog-home1 [class*=col-]:hover {
  -webkit-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  transform: translateY(-10px);
}

.landing-page .icons-section {
  background-position: 200px center, right 200px center;
  background-repeat: no-repeat;
}

@media (max-width: 1024px) {
  .landing-page .static-slider10 .title {
    font-size: 60px;
    line-height: 60px;
  }

  .landing-page .topbar {
    background: #263238;
  }
}

/*
Template Name: Wrakit UI Kit
Author: wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*
Template Name: Wrapkit
Author: wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*Theme Colors*/
/*bootstrap Color*/
/*Light colors*/
/*Normal Color*/
/*Extra Variable*/
/*Gradiant*/
/******************* 
This is the font import from the google api 
*******************/
@font-face {
  font-family: 'Calafia-Regular';
  src: url("../../fonts/Calafia-Regular.eot?#iefix") format("embedded-opentype"), url("../../fonts/Calafia-Regular.otf") format("opentype"), url("../../fonts/Calafia-Regular.woff") format("woff"), url("../../fonts/Calafia-Regular.ttf") format("truetype"), url("../../fonts/Calafia-Regular.svg#Calafia-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lavanderia-Sturdy';
  src: url("../../fonts/Lavanderia-Sturdy.eot?#iefix") format("embedded-opentype"), url("../../fonts/Lavanderia-Sturdy.otf") format("opentype"), url("../../fonts/Lavanderia-Sturdy.woff") format("woff"), url("../../fonts/Lavanderia-Sturdy.ttf") format("truetype"), url("../../fonts/Lavanderia-Sturdy.svg#Lavanderia-Sturdy") format("svg");
  font-weight: normal;
  font-style: normal;
}

/******************* 
Global Styles 
*******************/
* {
  outline: none;
}

body {
  background: #ffffff;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  overflow-x: hidden;
  color: #8d97ad;
  font-weight: 300;
}

html {
  position: relative;
  min-height: 100%;
  background: #ffffff;
}

a:hover,
a:focus {
  text-decoration: none;
}

a.link {
  color: #3e4555;
}

a.link:hover,
a.link:focus {
  color: #019e7d;
}

a.white-link {
  color: #ffffff;
}

a.white-link:hover,
a.white-link:focus {
  color: #ffffff;
}

.img-responsive {
  width: 100%;
  height: auto;
  display: inline-block;
}

.img-rounded {
  border-radius: 4px;
}

html body .mdi:before,
html body .mdi-set {
  line-height: initial;
}

/*******************
Headings
*******************/
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #3e4555;
  font-family: "Montserrat", sans-serif;
}

h1 {
  line-height: 40px;
  font-size: 36px;
}

h2 {
  line-height: 36px;
  font-size: 30px;
}

h3 {
  line-height: 30px;
  font-size: 24px;
}

h4 {
  line-height: 26px;
  font-size: 21px;
}

h5 {
  line-height: 22px;
  font-size: 18px;
  font-weight: 400;
}

h6 {
  line-height: 20px;
  font-size: 16px;
  font-weight: 400;
}

.display-5 {
  font-size: 3rem;
}

.display-6 {
  font-size: 36px;
}

.display-7 {
  font-size: 26px;
}

.box {
  border-radius: 4px;
  padding: 10px;
}

html body .dl {
  display: inline-block;
}

html body .db {
  display: block;
}

.no-wrap td,
.no-wrap th {
  white-space: nowrap;
}

.circle {
  border-radius: 100%;
}

/*******************
Blockquote
*******************/
html body blockquote {
  border-left: 5px solid #019e7d;
  border: 1px solid rgba(120, 130, 140, 0.13);
  padding: 15px;
}

.clear {
  clear: both;
}

ol li {
  margin: 5px 0;
}

/*******************
Paddings
*******************/
html body .p-0 {
  padding: 0px;
}

html body .p-10 {
  padding: 10px;
}

html body .p-15 {
  padding: 15px;
}

html body .p-20 {
  padding: 20px;
}

html body .p-30 {
  padding: 30px;
}

html body .p-40 {
  padding: 40px;
}

html body .p-l-0 {
  padding-left: 0px;
}

html body .p-l-10 {
  padding-left: 10px;
}

html body .p-l-20 {
  padding-left: 20px;
}

html body .p-r-0 {
  padding-right: 0px;
}

html body .p-r-10 {
  padding-right: 10px;
}

html body .p-r-20 {
  padding-right: 20px;
}

html body .p-r-30 {
  padding-right: 30px;
}

html body .p-r-40 {
  padding-right: 40px;
}

html body .p-t-0 {
  padding-top: 0px;
}

html body .p-t-10 {
  padding-top: 10px;
}

html body .p-t-20 {
  padding-top: 20px;
}

html body .p-t-30 {
  padding-top: 30px;
}

html body .p-b-0 {
  padding-bottom: 0px;
}

html body .p-b-5 {
  padding-bottom: 5px;
}

html body .p-b-10 {
  padding-bottom: 10px;
}

html body .p-b-20 {
  padding-bottom: 20px;
}

html body .p-b-30 {
  padding-bottom: 30px;
}

html body .p-b-40 {
  padding-bottom: 40px;
}

/*******************
Margin
*******************/
html body .m-0 {
  margin: 0px;
}

html body .m-l-5 {
  margin-left: 5px;
}

html body .m-l-10 {
  margin-left: 10px;
}

html body .m-l-15 {
  margin-left: 15px;
}

html body .m-l-20 {
  margin-left: 20px;
}

html body .m-l-30 {
  margin-left: 30px;
}

html body .m-l-40 {
  margin-left: 40px;
}

html body .m-r-5 {
  margin-right: 5px;
}

html body .m-r-10 {
  margin-right: 10px;
}

html body .m-r-15 {
  margin-right: 15px;
}

html body .m-r-20 {
  margin-right: 20px;
}

html body .m-r-30 {
  margin-right: 30px;
}

html body .m-r-40 {
  margin-right: 40px;
}

html body .m-t-0 {
  margin-top: 0px;
}

html body .m-t-5 {
  margin-top: 5px;
}

html body .m-t-10 {
  margin-top: 10px;
}

html body .m-t-15 {
  margin-top: 15px;
}

html body .m-t-20 {
  margin-top: 20px;
}

html body .m-t-30 {
  margin-top: 30px;
}

html body .m-t-40 {
  margin-top: 40px;
}

html body .m-b-0 {
  margin-bottom: 0px;
}

html body .m-b-5 {
  margin-bottom: 5px;
}

html body .m-b-10 {
  margin-bottom: 10px;
}

html body .m-b-15 {
  margin-bottom: 15px;
}

html body .m-b-20 {
  margin-bottom: 20px;
}

html body .m-b-30 {
  margin-bottom: 30px;
}

html body .m-b-40 {
  margin-bottom: 40px;
}

html body .minus-margin {
  margin-top: -200px;
}

/*******************
vertical alignment
*******************/
html body .vt {
  vertical-align: top;
}

html body .vm {
  vertical-align: middle;
}

html body .vb {
  vertical-align: bottom;
}

/*******************
Opacity
*******************/
.op-8 {
  opacity: 0.8;
}

.op-7 {
  opacity: 0.7;
}

.op-5 {
  opacity: 0.5;
}

.op-3 {
  opacity: 0.3;
}

/*******************
font weight
*******************/
html body .font-bold {
  font-weight: 700;
}

html body .font-semibold {
  font-weight: 600;
}

html body .font-normal {
  font-weight: normal;
}

html body .font-light {
  font-weight: 300;
}

html body .font-medium {
  font-weight: 500;
}

html body .font-16 {
  font-size: 16px;
}

html body .font-14 {
  font-size: 14px;
}

html body .font-13 {
  font-size: 13px;
}

html body .font-10 {
  font-size: 10px;
}

html body .font-18 {
  font-size: 18px;
}

html body .font-20 {
  font-size: 20px;
}

html body .font-stylish {
  font-family: "Calafia-Regular";
}

/*******************
Border
*******************/
html body .b-0 {
  border: none !important;
}

html body .b-r {
  border-right: 1px solid rgba(120, 130, 140, 0.13);
}

html body .b-l {
  border-left: 1px solid rgba(120, 130, 140, 0.13);
}

html body .b-b {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}

html body .b-t {
  border-top: 1px solid rgba(120, 130, 140, 0.13);
}

html body .b-all {
  border: 1px solid rgba(120, 130, 140, 0.13) !important;
}

/*******************
Thumb size
*******************/
.thumb-sm {
  height: 32px;
  width: 32px;
}

.thumb-md {
  height: 48px;
  width: 48px;
}

.thumb-lg {
  height: 88px;
  width: 88px;
}

.hide {
  display: none;
}

.img-circle {
  border-radius: 100%;
}

.radius {
  border-radius: 4px;
}

/*******************
Text Colors
*******************/
.text-white {
  color: #ffffff !important;
}

.text-danger {
  color: #ff4d7e !important;
}

.text-muted {
  color: #8d97ad !important;
}

.text-warning {
  color: #fec500 !important;
}

.text-success {
  color: #2cdd9b !important;
}

.text-info {
  color: #188ef4 !important;
}

.text-inverse {
  color: #3e4555 !important;
}

.text-success-gradiant {
  background: #2cdd9b;
  background: -webkit-linear-gradient(legacy-direction(to right), #2cdd9b 0%, #1dc8cc 100%);
  background: -webkit-gradient(linear, left top, right top, from(#2cdd9b), to(#1dc8cc));
  background: -webkit-linear-gradient(left, #2cdd9b 0%, #1dc8cc 100%);
  background: -o-linear-gradient(left, #2cdd9b 0%, #1dc8cc 100%);
  background: linear-gradient(to right, #2cdd9b 0%, #1dc8cc 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
}

.text-info-gradiant {
  background: #188ef4;
  background: -webkit-linear-gradient(legacy-direction(to right), #188ef4 0%, #019e7d 100%);
  background: -webkit-gradient(linear, left top, right top, from(#188ef4), to(#019e7d));
  background: -webkit-linear-gradient(left, #188ef4 0%, #019e7d 100%);
  background: -o-linear-gradient(left, #188ef4 0%, #019e7d 100%);
  background: linear-gradient(to right, #188ef4 0%, #019e7d 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
}

.text-danger-gradiant {
  background: #ff4d7e;
  background: -webkit-linear-gradient(legacy-direction(to right), #ff4d7e 0%, #ff6a5b 100%);
  background: -webkit-gradient(linear, left top, right top, from(#ff4d7e), to(#ff6a5b));
  background: -webkit-linear-gradient(left, #ff4d7e 0%, #ff6a5b 100%);
  background: -o-linear-gradient(left, #ff4d7e 0%, #ff6a5b 100%);
  background: linear-gradient(to right, #ff4d7e 0%, #ff6a5b 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
}

html body .text-blue {
  color: #02bec9;
}

html body .text-purple {
  color: #7460ee;
}

html body .text-primary {
  color: #019e7d !important;
}

html body .text-megna {
  color: #1dc8cd;
}

html body .text-dark {
  color: #8d97ad;
}

html body .text-themecolor {
  color: #019e7d;
}

html body .text-purple-gradiant {
  background: #7460ee;
  background: -webkit-linear-gradient(legacy-direction(to right), #7460ee 0%, #4d3f95 100%);
  background: -webkit-gradient(linear, left top, right top, from(#7460ee), to(#4d3f95));
  background: -webkit-linear-gradient(left, #7460ee 0%, #4d3f95 100%);
  background: -o-linear-gradient(left, #7460ee 0%, #4d3f95 100%);
  background: linear-gradient(to right, #7460ee 0%, #4d3f95 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
}

/*******************
Background Colors
*******************/
.bg-primary {
  background-color: #019e7d !important;
}

.bg-success {
  background-color: #2cdd9b !important;
}

.bg-info {
  background-color: #188ef4 !important;
}

.bg-info-dark {
  background-color: #27b1e4 !important;
}

.bg-warning {
  background-color: #fec500 !important;
}

.bg-danger {
  background-color: #ff4d7e !important;
}

.bg-orange {
  background-color: #ff6a5b !important;
}

.bg-yellow {
  background-color: #fed700;
}

.bg-facebook {
  background-color: #3b5a9a;
}

.bg-twitter {
  background-color: #56adf2;
}

.bg-success-gradiant {
  background: #2cdd9b;
  background: -webkit-linear-gradient(legacy-direction(to right), #2cdd9b 0%, #1dc8cc 100%);
  background: -webkit-gradient(linear, left top, right top, from(#2cdd9b), to(#1dc8cc));
  background: -webkit-linear-gradient(left, #2cdd9b 0%, #1dc8cc 100%);
  background: -o-linear-gradient(left, #2cdd9b 0%, #1dc8cc 100%);
  background: linear-gradient(to right, #2cdd9b 0%, #1dc8cc 100%);
}

.bg-info-gradiant {
  background: #188ef4;
  background: -webkit-linear-gradient(legacy-direction(to right), #188ef4 0%, #019e7d 100%);
  background: -webkit-gradient(linear, left top, right top, from(#188ef4), to(#019e7d));
  background: -webkit-linear-gradient(left, #188ef4 0%, #019e7d 100%);
  background: -o-linear-gradient(left, #188ef4 0%, #019e7d 100%);
  background: linear-gradient(to right, #188ef4 0%, #019e7d 100%);
}

.bg-danger-gradiant {
  background: #ff4d7e;
  background: -webkit-linear-gradient(legacy-direction(to right), #ff4d7e 0%, #ff6a5b 100%);
  background: -webkit-gradient(linear, left top, right top, from(#ff4d7e), to(#ff6a5b));
  background: -webkit-linear-gradient(left, #ff4d7e 0%, #ff6a5b 100%);
  background: -o-linear-gradient(left, #ff4d7e 0%, #ff6a5b 100%);
  background: linear-gradient(to right, #ff4d7e 0%, #ff6a5b 100%);
}

.bg-purple-gradiant {
  background: #7460ee;
  background: -webkit-linear-gradient(legacy-direction(to right), #7460ee 0%, #4d3f95 100%);
  background: -webkit-gradient(linear, left top, right top, from(#7460ee), to(#4d3f95));
  background: -webkit-linear-gradient(left, #7460ee 0%, #4d3f95 100%);
  background: -o-linear-gradient(left, #7460ee 0%, #4d3f95 100%);
  background: linear-gradient(to right, #7460ee 0%, #4d3f95 100%);
}

.bg-warning-gradiant {
  background: #fec500;
  background: -webkit-linear-gradient(legacy-direction(to right), #fec500 0%, #ff8e49 100%);
  background: -webkit-gradient(linear, left top, right top, from(#fec500), to(#ff8e49));
  background: -webkit-linear-gradient(left, #fec500 0%, #ff8e49 100%);
  background: -o-linear-gradient(left, #fec500 0%, #ff8e49 100%);
  background: linear-gradient(to right, #fec500 0%, #ff8e49 100%);
}

html body .bg-megna {
  background-color: #1dc8cd;
}

html body .bg-theme {
  background-color: #019e7d;
}

html body .bg-inverse {
  background-color: #3e4555;
}

html body .bg-purple {
  background-color: #7460ee;
}

html body .bg-light {
  background-color: #f4f8fa !important;
}

html body .bg-light-primary {
  background-color: #f1effd;
}

html body .bg-light-success {
  background-color: #e8fdeb;
}

html body .bg-light-info {
  background-color: #cfecfe;
}

html body .bg-light-extra {
  background-color: #ebf3f5;
}

html body .bg-light-warning {
  background-color: #fff8ec;
}

html body .bg-light-danger {
  background-color: #f9e7eb;
}

html body .bg-light-inverse {
  background-color: #f6f6f6;
}

html body .bg-light {
  background-color: #f4f8fa;
}

html body .bg-white {
  background-color: #ffffff;
}

/*******************
Rounds
*******************/
.round {
  line-height: 48px;
  color: #ffffff;
  width: 50px;
  height: 50px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  border-radius: 100%;
  background: #188ef4;
}

.round img {
  border-radius: 100%;
}

.round-lg {
  line-height: 65px;
  width: 60px;
  height: 60px;
  font-size: 30px;
}

.round.round-info {
  background: #188ef4;
}

.round.round-warning {
  background: #fec500;
}

.round.round-danger {
  background: #ff4d7e;
}

.round.round-success {
  background: #2cdd9b;
}

.round.round-primary {
  background: #019e7d;
}

/*******************
Labels
*******************/
.label {
  padding: 3px 15px;
  color: #ffffff;
  font-weight: 400;
  border-radius: 4px;
  font-size: 75%;
}

.label-rounded {
  border-radius: 60px;
}

.label-custom {
  background-color: #1dc8cd;
}

.label-success {
  background-color: #2cdd9b;
}

.label-info {
  background-color: #188ef4;
}

.label-warning {
  background-color: #fec500;
}

.label-danger {
  background-color: #ff4d7e;
}

.label-megna {
  background-color: #1dc8cd;
}

.label-primary {
  background-color: #019e7d;
}

.label-purple {
  background-color: #7460ee;
}

.label-red {
  background-color: #fb3a3a;
}

.label-inverse {
  background-color: #3e4555;
}

.label-default {
  background-color: #f4f8fa;
}

.label-white {
  background-color: #ffffff;
}

.label-light-success {
  background-color: #e8fdeb;
  color: #2cdd9b;
}

.label-light-info {
  background-color: #cfecfe;
  color: #188ef4;
}

.label-light-warning {
  background-color: #fff8ec;
  color: #fec500;
}

.label-light-danger {
  background-color: #f9e7eb;
  color: #ff4d7e;
}

.label-light-megna {
  background-color: #e0f2f4;
  color: #1dc8cd;
}

.label-light-primary {
  background-color: #f1effd;
  color: #019e7d;
}

.label-light-inverse {
  background-color: #f6f6f6;
  color: #3e4555;
}

/*******************
 Badge
******************/
.badge {
  font-weight: 400;
}

.badge-xs {
  font-size: 9px;
}

.badge-xs,
.badge-sm {
  -webkit-transform: translate(0, -2px);
  -ms-transform: translate(0, -2px);
  -o-transform: translate(0, -2px);
  transform: translate(0, -2px);
}

.badge-success {
  background-color: #2cdd9b;
}

.badge-info {
  background-color: #188ef4;
}

.badge-primary {
  background-color: #019e7d;
}

.badge-warning {
  background-color: #fec500;
}

.badge-danger {
  background-color: #ff4d7e;
}

.badge-purple {
  background-color: #7460ee;
}

.badge-red {
  background-color: #fb3a3a;
}

.badge-inverse {
  background-color: #3e4555;
}

/*******************
List-style-none
******************/
ul.list-style-none {
  margin: 0px;
  padding: 0px;
}

ul.list-style-none li {
  list-style: none;
}

ul.list-style-none li a {
  color: #8d97ad;
  padding: 8px 0px;
  display: block;
  text-decoration: none;
}

ul.list-style-none li a:hover {
  color: #019e7d;
}

/*******************
dropdown-item
******************/
.dropdown-item {
  padding: 8px 1rem;
  color: #8d97ad;
}

/*******************
Buttons
******************/
.btn {
  color: #ffffff;
  padding: 10px 25px;
  cursor: pointer;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);


  background: #019e7d;
  border: 1px solid #019e7d;
  color: #ffffff;

}

.btn:hover {
  color: #ffffff;
  background: #60b79f;
}

.btn:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-link {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #263238;
  padding: 10px 15px;
}

.btn-link .underline {
  border-bottom: 1px solid #263238;
}

.btn-link:hover {
  color: #2cdd9b;
}

.btn-light {
  color: #263238;
}

.btn-light:hover {
  background: #263238;
  color: #ffffff;
  border-color: #263238;
}

.btn-lg {
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
}

.btn-md {
  padding: 15px 45px;
  font-size: 16px;
}

.btn-sm {
  padding: .25rem .5rem;
  font-size: 12px;
}

.btn-xs {
  padding: .25rem .5rem;
  font-size: 10px;
}

.btn-circle {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  padding: 10px;
}

.btn-circle.btn-md {
  padding: 18px 0px;
  width: 60px;
  height: 60px;
  font-size: 20px;
}

.btn-circle.btn-sm {
  width: 35px;
  height: 35px;
  padding: 8px 10px;
  font-size: 14px;
}

.btn-circle.btn-lg {
  width: 70px;
  height: 70px;
  padding: 24px 15px;
  font-size: 20px;
  line-height: 23px;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 14px 15px;
  font-size: 24px;
}

.btn-rounded {
  border-radius: 60px;
  -webkit-border-radius: 60px;
}

.btn-arrow {
  position: relative;
}

.btn-arrow span {
  display: inline-block;
  position: relative;
  -webkit-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  will-change: transform;
}

.btn-arrow:hover span,
.btn-arrow:focus span {
  -webkit-transform: translate3d(-1rem, 0, 0);
  transform: translate3d(-1rem, 0, 0);
}

.btn-arrow i {
  position: absolute;
  width: 1.1em;
  right: 0px;
  right: 0rem;
  opacity: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  will-change: right, opacity;
}

.btn-arrow:hover i,
.btn-arrow:focus i {
  opacity: 1;
  right: -2rem;
}

.btn-secondary,
.btn-secondary.disabled {
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
  background-color: #ffffff;
  color: #8d97ad;
}

.btn-secondary:hover,
.btn-secondary.disabled:hover {
  color: #ffffff !important;
}

.btn-secondary.active,
.btn-secondary:active,
.btn-secondary:focus,
.btn-secondary.disabled.active,
.btn-secondary.disabled:active,
.btn-secondary.disabled:focus {
  color: #ffffff !important;
  background: #263238;
  border-color: #263238;
}

.btn-primary,
.btn-primary.disabled {
  background: #019e7d;
  border: 1px solid #019e7d;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.btn-primary:hover,
.btn-primary.disabled:hover {
  background: #345bcb;
  border: 1px solid #345bcb;
}

.btn-primary.active,
.btn-primary:active,
.btn-primary:focus,
.btn-primary.disabled.active,
.btn-primary.disabled:active,
.btn-primary.disabled:focus {
  background: #345bcb;
}

.btn-themecolor,
.btn-themecolor.disabled {
  background: #019e7d;
  color: #ffffff;
  border: 1px solid #019e7d;
}

.btn-themecolor:hover,
.btn-themecolor.disabled:hover {
  background: #028ee1;
  border: 1px solid #028ee1;
}

.btn-themecolor.active,
.btn-themecolor:active,
.btn-themecolor:focus,
.btn-themecolor.disabled.active,
.btn-themecolor.disabled:active,
.btn-themecolor.disabled:focus {
  background: #028ee1;
}

.btn-success,
.btn-success.disabled {
  background: #2cdd9b;
  border: 1px solid #2cdd9b;
  color: #ffffff;
}

.btn-success:hover,
.btn-success.disabled:hover {
  background: #1dc8cc;
  border: 1px solid #1dc8cc;
}

.btn-success.active,
.btn-success:active,
.btn-success:focus,
.btn-success.disabled.active,
.btn-success.disabled:active,
.btn-success.disabled:focus {
  background: #1dc8cc;
}

.btn-info,
.btn-info.disabled {
  background: #188ef4;
  border: 1px solid #188ef4;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.btn-info:hover,
.btn-info.disabled:hover {
  background: #27b1e4;
  border: 1px solid #27b1e4;
}

.btn-info.active,
.btn-info:active,
.btn-info:focus,
.btn-info.disabled.active,
.btn-info.disabled:active,
.btn-info.disabled:focus {
  background: #27b1e4;
}

.btn-warning,
.btn-warning.disabled {
  background: #fec500;
  color: #ffffff;
  border: 1px solid #fec500;
}

.btn-warning:hover,
.btn-warning.disabled:hover {
  background: #ff8e49;
  color: #ffffff;
  border: 1px solid #ff8e49;
}

.btn-warning.active,
.btn-warning:active,
.btn-warning:focus,
.btn-warning.disabled.active,
.btn-warning.disabled:active,
.btn-warning.disabled:focus {
  background: #ff8e49;
  color: #ffffff;
}

.btn-danger,
.btn-danger.disabled {
  background: #ff4d7e;
  border: 1px solid #ff4d7e;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.btn-danger:hover,
.btn-danger.disabled:hover {
  background: #d73e6b;
  border: 1px solid #d73e6b;
}

.btn-danger.active,
.btn-danger:active,
.btn-danger:focus,
.btn-danger.disabled.active,
.btn-danger.disabled:active,
.btn-danger.disabled:focus {
  background: #d73e6b;
}

.btn-inverse,
.btn-inverse.disabled {
  background: #3e4555;
  border: 1px solid #3e4555;
  color: #ffffff;
}

.btn-inverse:hover,
.btn-inverse.disabled:hover {
  background: #232a37;
  color: #ffffff;
  border: 1px solid #232a37;
}

.btn-inverse.active,
.btn-inverse:active,
.btn-inverse:focus,
.btn-inverse.disabled.active,
.btn-inverse.disabled:active,
.btn-inverse.disabled:focus {
  background: #232a37;
  color: #ffffff;
}

.btn-green {
  background: #019e7d;
  border: 1px solid #019e7d;
  color: #ffffff;
}
.btn-green:hover {
  background: #60b79f;
}

.btn-red,
.btn-red.disabled {
  background: #fb3a3a;
  border: 1px solid #fb3a3a;
  color: #ffffff;
}

.btn-red:hover,
.btn-red.disabled:hover {
  border: 1px solid #d61f1f;
  background: #d61f1f;
}

.btn-red.active,
.btn-red:active,
.btn-red:focus,
.btn-red.disabled.active,
.btn-red.disabled:active,
.btn-red.disabled:focus {
  background: #d73e6b;
}

.btn-success-gradiant {
  background: #2cdd9b;
  background: -webkit-linear-gradient(legacy-direction(to right), #2cdd9b 0%, #1dc8cc 100%);
  background: -webkit-gradient(linear, left top, right top, from(#2cdd9b), to(#1dc8cc));
  background: -webkit-linear-gradient(left, #2cdd9b 0%, #1dc8cc 100%);
  background: -o-linear-gradient(left, #2cdd9b 0%, #1dc8cc 100%);
  background: linear-gradient(to right, #2cdd9b 0%, #1dc8cc 100%);
  border: 0px;
}

.btn-success-gradiant:hover {
  background: #1dc8cc;
  background: -webkit-linear-gradient(legacy-direction(to right), #1dc8cc 0%, #2cdd9b 100%);
  background: -webkit-gradient(linear, left top, right top, from(#1dc8cc), to(#2cdd9b));
  background: -webkit-linear-gradient(left, #1dc8cc 0%, #2cdd9b 100%);
  background: -o-linear-gradient(left, #1dc8cc 0%, #2cdd9b 100%);
  background: linear-gradient(to right, #1dc8cc 0%, #2cdd9b 100%);
}

.btn-success-gradiant.active,
.btn-success-gradiant:active,
.btn-success-gradiant:focus {
  -webkit-box-shadow: 0px;
  box-shadow: 0px;
  opacity: 1;
}

.btn-danger-gradiant {
  background: #ff4d7e;
  background: -webkit-linear-gradient(legacy-direction(to right), #ff4d7e 0%, #ff6a5b 100%);
  background: -webkit-gradient(linear, left top, right top, from(#ff4d7e), to(#ff6a5b));
  background: -webkit-linear-gradient(left, #ff4d7e 0%, #ff6a5b 100%);
  background: -o-linear-gradient(left, #ff4d7e 0%, #ff6a5b 100%);
  background: linear-gradient(to right, #ff4d7e 0%, #ff6a5b 100%);
  border: 0px;
}

.btn-danger-gradiant:hover {
  background: #ff6a5b;
  background: -webkit-linear-gradient(legacy-direction(to right), #ff6a5b 0%, #ff4d7e 100%);
  background: -webkit-gradient(linear, left top, right top, from(#ff6a5b), to(#ff4d7e));
  background: -webkit-linear-gradient(left, #ff6a5b 0%, #ff4d7e 100%);
  background: -o-linear-gradient(left, #ff6a5b 0%, #ff4d7e 100%);
  background: linear-gradient(to right, #ff6a5b 0%, #ff4d7e 100%);
}

.btn-danger-gradiant.active,
.btn-danger-gradiant:active,
.btn-danger-gradiant:focus {
  -webkit-box-shadow: 0px;
  box-shadow: 0px;
  opacity: 1;
}

.btn-info-gradiant {
  background: #188ef4;
  background: -webkit-linear-gradient(legacy-direction(to right), #188ef4 0%, #019e7d 100%);
  background: -webkit-gradient(linear, left top, right top, from(#188ef4), to(#019e7d));
  background: -webkit-linear-gradient(left, #188ef4 0%, #019e7d 100%);
  background: -o-linear-gradient(left, #188ef4 0%, #019e7d 100%);
  background: linear-gradient(to right, #188ef4 0%, #019e7d 100%);
  border: 0px;
  color: #ffffff;
}

.btn-info-gradiant:hover {
  background: #019e7d;
  background: -webkit-linear-gradient(legacy-direction(to right), #019e7d 0%, #188ef4 100%);
  background: -webkit-gradient(linear, left top, right top, from(#019e7d), to(#188ef4));
  background: -webkit-linear-gradient(left, #019e7d 0%, #188ef4 100%);
  background: -o-linear-gradient(left, #019e7d 0%, #188ef4 100%);
  background: linear-gradient(to right, #019e7d 0%, #188ef4 100%);
}

.btn-info-gradiant.active,
.btn-info-gradiant:active,
.btn-info-gradiant:focus {
  -webkit-box-shadow: 0px;
  box-shadow: 0px;
  opacity: 1;
}

.btn-outline-secondary {
  background-color: #ffffff;
  color: #727b84;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.btn-outline-secondary.active,
.btn-outline-secondary:active,
.btn-outline-secondary:focus {
  background: #263238;
}

.btn-outline-primary {
  color: #019e7d;
  background-color: #ffffff;
  border-color: #019e7d;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary.focus {
  background: #019e7d;
  color: #ffffff;
  border-color: #019e7d;
}

.btn-outline-primary.active,
.btn-outline-primary:active,
.btn-outline-primary:focus {
  background: #345bcb;
}

.btn-outline-success,
a.btn-outline-success {
  color: #2cdd9b;
  background-color: transparent;
  border-color: #2cdd9b;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.btn-outline-success:hover,
.btn-outline-success:focus,
.btn-outline-success.focus,
a.btn-outline-success:hover,
a.btn-outline-success:focus,
a.btn-outline-success.focus {
  background: #2cdd9b;
  border-color: #2cdd9b;
  color: #ffffff;
}

.btn-outline-success.active,
.btn-outline-success:active,
.btn-outline-success:focus,
a.btn-outline-success.active,
a.btn-outline-success:active,
a.btn-outline-success:focus {
  background: #1dc8cc;
}

.btn-outline-info {
  color: #188ef4;
  background-color: transparent;
  border-color: #188ef4;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.btn-outline-info:hover,
.btn-outline-info:focus,
.btn-outline-info.focus {
  background: #188ef4;
  border-color: #188ef4;
  color: #ffffff;
}

.btn-outline-info.active,
.btn-outline-info:active,
.btn-outline-info:focus {
  background: #27b1e4;
}

.btn-outline-warning {
  color: #fec500;
  background-color: transparent;
  border-color: #fec500;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.btn-outline-warning:hover,
.btn-outline-warning:focus,
.btn-outline-warning.focus {
  background: #fec500;
  border-color: #fec500;
  color: #ffffff;
}

.btn-outline-warning.active,
.btn-outline-warning:active,
.btn-outline-warning:focus {
  background: #ff8e49;
}

.btn-outline-danger {
  color: #ff4d7e;
  background-color: transparent;
  border-color: #ff4d7e;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.btn-outline-danger:hover,
.btn-outline-danger:focus,
.btn-outline-danger.focus {
  background: #ff4d7e;
  border-color: #ff4d7e;
  color: #ffffff;
}

.btn-outline-danger.active,
.btn-outline-danger:active,
.btn-outline-danger:focus {
  background: #d73e6b;
}

.btn-outline-red {
  color: #fb3a3a;
  background-color: transparent;
  border-color: #fb3a3a;
}

.btn-outline-red:hover,
.btn-outline-red:focus,
.btn-outline-red.focus {
  background: #fb3a3a;
  border-color: #fb3a3a;
  color: #ffffff;
}

.btn-outline-red.active,
.btn-outline-red:active,
.btn-outline-red:focus {
  background: #d73e6b;
}

.btn-outline-inverse {
  color: #3e4555;
  background-color: transparent;
  border-color: #3e4555;
}

.btn-outline-inverse:hover,
.btn-outline-inverse:focus,
.btn-outline-inverse.focus {
  background: #3e4555;
  border-color: #3e4555;
  color: #ffffff;
}

.btn-outline-light:hover {
  color: #263238 !important;
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open>.dropdown-toggle.btn-primary.focus,
.open>.dropdown-toggle.btn-primary:focus,
.open>.dropdown-toggle.btn-primary:hover,
.btn-primary.focus,
.btn-primary:focus {
  background-color: #345bcb;
  border: 1px solid #345bcb;
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open>.dropdown-toggle.btn-success.focus,
.open>.dropdown-toggle.btn-success:focus,
.open>.dropdown-toggle.btn-success:hover,
.btn-success.focus,
.btn-success:focus {
  background-color: #1dc8cc;
  border: 1px solid #1dc8cc;
}

.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open>.dropdown-toggle.btn-info.focus,
.open>.dropdown-toggle.btn-info:focus,
.open>.dropdown-toggle.btn-info:hover,
.btn-info.focus,
.btn-info:focus {
  background-color: #27b1e4;
  border: 1px solid #27b1e4;
}

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open>.dropdown-toggle.btn-warning.focus,
.open>.dropdown-toggle.btn-warning:focus,
.open>.dropdown-toggle.btn-warning:hover,
.btn-warning.focus,
.btn-warning:focus {
  background-color: #ff8e49;
  border: 1px solid #ff8e49;
}

.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open>.dropdown-toggle.btn-danger.focus,
.open>.dropdown-toggle.btn-danger:focus,
.open>.dropdown-toggle.btn-danger:hover,
.btn-danger.focus,
.btn-danger:focus {
  background-color: #d73e6b;
  border: 1px solid #d73e6b;
}

.btn-inverse:hover,
.btn-inverse:focus,
.btn-inverse:active,
.btn-inverse.active,
.btn-inverse.focus,
.btn-inverse:active,
.btn-inverse:focus,
.btn-inverse:hover,
.open>.dropdown-toggle.btn-inverse {
  background-color: #232a37;
  border: 1px solid #232a37;
}

.btn-red:hover,
.btn-red:focus,
.btn-red:active,
.btn-red.active,
.btn-red.focus,
.btn-red:active,
.btn-red:focus,
.btn-red:hover,
.open>.dropdown-toggle.btn-red {
  background-color: #d61f1f;
  border: 1px solid #d61f1f;
  color: #ffffff;
}

.btn span.btn-devider {
  display: inline-block;
  padding-left: 10px;
}

/*-------------------*/
/*On off switch*/
/*-------------------*/
.onoffswitch {
  position: relative;
  width: 70px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  margin: 0 auto;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 20px;
}

.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 30px;
  padding: 0;
  line-height: 30px;
  font-size: 14px;
  color: white;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.onoffswitch-inner:before {
  content: "";
  padding-right: 27px;
  background-color: #2cdd9b;
  color: #FFFFFF;
}

.onoffswitch-inner:after {
  content: "";
  padding-right: 24px;
  background-color: #3e4555;
  color: #999999;
  text-align: right;
}

.onoffswitch-switch {
  display: block;
  width: 23px;
  margin: 6px;
  background: #FFFFFF;
  height: 23px;
  position: absolute;
  top: -1px;
  bottom: 0;
  right: 35px;
  border-radius: 20px;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch {
  right: 0px;
}

/*-------------------*/
/*Bootstrap 4 hack*/
/*-------------------*/
.card-columns {
  -webkit-column-gap: 30px;
  column-gap: 30px;
}

.card-columns .card {
  margin-bottom: 30px;
}

.invisible {
  visibility: hidden !important;
}

.hidden-xs-up {
  display: none !important;
}

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important;
  }
}

@media (max-width: 1199px) {
  .hidden-lg-down {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important;
  }
}

.hidden-xl-down {
  display: none !important;
}

/*
Template Name: Wrakit UI Kit
Author: wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/******************* 
Topbar
*******************/
.topbar {
  padding: 0px;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
  width: 100%;
  z-index: 20;
}

.topbar.fixed-header {
  -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
  box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
  position: fixed;
}

.topbar.fixed-header .navbar .navbar-brand {
  width: 70px;
}


.topbar.fixed-header .navbar .navbar-brand img {
  height: 70px;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
}

/*******************
Header 6
*******************/
.header6 .navbar-toggler {
  color: #ffffff;
}

.header6 .navbar-brand {
  line-height: 80px;
}

.h6-nav-bar {
  padding: 0px;
}

.h6-nav-bar .navbar-nav .nav-link {
  padding: 35px 15px;
  color: rgba(255, 255, 255, 0.5);
}

.h6-nav-bar .navbar-nav .nav-item:hover .nav-link,
.h6-nav-bar .navbar-nav .nav-item.active .nav-link {
  color: white;
}

.h6-nav-bar .act-buttons .btn {
  margin: 10px 0 10px 10px;
}

@media (max-width: 1023px) {
  .h6-nav-bar .navbar-nav .nav-link {
    padding: 15px 15px;
  }

  .h6-nav-bar .act-buttons .btn {
    margin: 10px 0 10px 0px;
  }
}

/******************* 
Main & Page wrapper
*******************/
.page-wrapper {
  padding-top: 0px;
}

#main-wrapper {
  overflow: hidden;
}

/******************* 
container fluid
*******************/
.container-fluid {
  padding: 0px;
}

/******************* 
footer
*******************/
.footer {
  padding: 20px 0;
  text-align: center;
}

/******************* 
Fix-width
*******************/
.fix-width {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
}

/******************* 
Spacer
*******************/
.spacer {
  padding: 90px 0;
}

.mini-spacer {
  padding: 40px 0;
}

/******************* 
Title
*******************/
.title {
  margin: 20px 0 15px;
}

/******************* 
Subtitle
*******************/
.subtitle {
  color: #8d97ad;
  line-height: 24px;
}

/******************* 
card
*******************/
.card {
  border: 0px;
  margin-bottom: 30px;
}

.card.card-shadow {
  -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
  box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
}

.card-group .card {
  border: 1px solid rgba(120, 130, 140, 0.13);
  margin-bottom: 30px;
}

/******************* 
Icon space
*******************/
.icon-space {
  margin: 20px 0;
}

.linking {
  color: #3e4555;
}

.linking i {
  font-size: 14px;
  margin-left: 10px;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.linking:hover {
  color: #019e7d;
}

.linking:hover i {
  margin-left: 15px;
}

/******************* 
Listing
*******************/
.list-block {
  margin: 0px;
  padding: 0px;
}

.list-block li {
  list-style: none;
  padding: 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.list-block li i {
  margin-right: 10px;
  -ms-flex-negative: 0px;
  flex-shrink: 0px;
  margin-top: 3px;
}

.list-block.with-underline li {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
  padding: 15px 0;
}

/******************* 
Listing
*******************/
.img-inline {
  margin: 0px;
  padding: 0px;
}

.img-inline li {
  list-style: none;
}

.img-inline li.half-width {
  width: 49%;
  display: inline-block;
  vertical-align: top;
}

/******************* 
Image shadow
*******************/
.img-shadow {
  -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.3);
  box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.3);
}

/******************* 
Image shadow
*******************/
.icon-round {
  width: 80px;
  line-height: 80px;
  text-align: center;
  border-radius: 100%;
  display: inline-block;
}

.no-shrink {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

/******************* 
up section
*******************/
.up {
  z-index: 10;
  position: relative;
}

.ui-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
  width: 40px;
  height: 40px;
  color: #ffffff;
  background: #019e7d;
  border-radius: 50px;
  line-height: 40px;
  text-align: center;
  font-size: 20px;
  -webkit-transform: translateY(100px);
  -ms-transform: translateY(100px);
  transform: translateY(100px);
}

.ui-to-top.active {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.ui-to-top:hover,
.ui-to-top:focus {
  color: #ffffff;
}

/******************* 
both space
*******************/
.both-space {
  margin: 80px 0;
}

/******************* 
Side text content
*******************/
.side-content {
  padding: 40px 60px;
}

/******************* 
background cover
*******************/
.bg-cover {
  background-size: cover;
  min-height: 200px;
}

/******************* 
Owl carousel
*******************/
.owl-carousel {
  display: block;
  width: 100%;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
}

.owl-carousel .owl-stage {
  position: relative;
}

.owl-carousel .owl-item {
  float: left;
}

.owl-carousel .owl-nav.disabled {
  display: none;
}

/******************* 
Max width
*******************/
.max-300 {
  max-width: 300px;
}

.max-400 {
  max-width: 400px;
}

.max-500 {
  max-width: 500px;
}

.max-600 {
  max-width: 600px;
}

/******************* 
line sticks
*******************/
.stick-bottom {
  position: relative;
  margin-bottom: 30px;
}

.stick-bottom>span {
  width: 30px;
  height: 2px;
  position: absolute;
  bottom: -7px;
  left: 0px;
  display: inline-block;
}

/******************* 
position
*******************/
.po-absolute {
  position: absolute;
}

.po-relative {
  position: relative;
}

/*-------------------*/
/*Bootstrap 4 hack*/
/*-------------------*/
.invisible {
  visibility: hidden !important;
}

.hidden-xs-up {
  display: none !important;
}

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important;
  }
}

@media (max-width: 1200px) {
  .header3 .h3-navbar .navbar .navbar-nav .nav-item .nav-link {
    font-size: 13px;
    padding: 22px 15px;
  }
}

@media (max-width: 1199px) {
  .hidden-lg-down {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important;
  }
}

.hidden-xl-down {
  display: none !important;
}

/*************/
/* Dropdown*/
/*************/
.navbar-nav .dropdown-toggle::after {
  display: none;
}

.navbar-nav .dropdown-menu.b-none {
  border: 0px;
  -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
  box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
}

.navbar-nav {
  font-weight: 400;
}

.navbar-nav .dropdown-menu.b-none {
  border: 0px;
  -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
  box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
}

.pre-scroll {
  position: relative;
}

.mega-dropdown {
  position: static;
}

.mega-dropdown .dropdown-menu {
  width: 100%;
  padding: 30px;
  margin-top: 0px;
  overflow: hidden;
}

.mega-dropdown .list-style-none {
  font-size: 14px;
}

.mega-dropdown .inside-bg {
  margin: -30px 0 -30px -30px;
}

.mega-dropdown .inside-bg-right {
  margin: -30px -30px -30px 0;
}

.mega-dropdown .bg-img {
  background-size: cover;
  height: 100%;
  padding: 30px;
  position: absolute;
  margin-right: 15px;
}

.mega-dropdown h6 {
  margin: 15px 0;
  font-size: 15px;
}

/*************/
/* General Listing*/
/*************/
.general-listing {
  padding: 0px;
  margin: 0px;
}

.general-listing li {
  list-style: none;
}

.general-listing li a {
  color: #8d97ad;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 0;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.general-listing li:hover a {
  color: #019e7d;
  padding-left: 10px;
}

.general-listing li i {
  margin-right: 7px;
  vertical-align: middle;
}

.general-listing.two-part li {
  width: 49%;
  display: inline-block;
}

.general-listing.only-li li {
  padding: 5px 0;
}

/*************/
/* form dark*/
/*************/
.form-control-dark {
  background: rgba(120, 130, 140, 0.13);
  color: #ffffff;
  border-color: rgba(120, 130, 140, 0.13);
}

.form-control-dark:focus {
  background: rgba(120, 130, 140, 0.13);
  color: #ffffff;
}

/*************/
/* social-round */
/*************/
.round-social a {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  display: inline-block;
  border-radius: 100%;
  margin: 0 5px;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.round-social a:hover {
  -webkit-transform: translate3d(0px, -5px, 0px);
  transform: translate3d(0px, -5px, 0px);
}

.round-social.light a {
  color: #263238;
  background: #f4f8fa;
}

.round-social.light a:hover {
  background: #263238;
  color: #ffffff;
}

/*************/
/* Form Banner */
/*************/
.banner {
  position: relative;
  max-height: 700px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  width: 100%;
  display: table;
}

.banner .banner-content {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}

@media (max-width: 992px) {
  .banner {
    height: auto !important;
    padding: 100px 0;
  }
}

@media (max-width: 767px) {
  .banner {
    padding: 30px 0;
  }
}

.form-control {
  padding: .75rem;
  border: 2px solid rgba(120, 130, 140, 0.13);
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 12px);
}

/*******************
UI-Elements
******************/
/*******************
Buttons
******************/
.button-group .btn {
  margin-bottom: 7px;
}

/*******************
Grid page
******************/
.show-grid {
  margin-bottom: 10px;
  padding: 0 15px;
}

.show-grid [class^=col-] {
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid rgba(120, 130, 140, 0.13);
  background-color: #f4f8fa;
}

/*******************
list and media
******************/
.list-group a.list-group-item:hover {
  background: #f4f8fa;
}

.list-group-item.active,
.list-group .list-group-item.active:hover {
  background: #019e7d;
  border-color: #019e7d;
}

.list-group-item.disabled {
  color: #8d97ad;
  background: #f4f8fa;
}

.media {
  border: 1px solid rgba(120, 130, 140, 0.13);
  margin-bottom: 10px;
  padding: 15px;
}

/*******************
Notification page Jquery toaster
******************/
.alert-rounded {
  border-radius: 60px;
}

/*******************
Progress bar
******************/
.progress.active .progress-bar,
.progress-bar.active {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}

.progress-vertical {
  min-height: 250px;
  height: 250px;
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  margin-right: 20px;
}

.progress-vertical-bottom {
  min-height: 250px;
  height: 250px;
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  margin-right: 20px;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-animated {
  -webkit-animation-duration: 5s;
  -webkit-animation-name: myanimation;
  -webkit-transition: 5s all;
  animation-duration: 5s;
  animation-name: myanimation;
  -o-transition: 5s all;
  transition: 5s all;
}

@-webkit-keyframes myanimation {
  from {
    width: 0;
  }
}

@keyframes myanimation {
  from {
    width: 0;
  }
}

/*Mystyle*/
.progress {
  height: 6px;
}

/*******************
Vertical tabs
******************/
.vtabs {
  display: table;
}

.vtabs .tabs-vertical {
  width: 150px;
  border-bottom: 0px;
  border-right: 1px solid rgba(120, 130, 140, 0.13);
  display: table-cell;
  vertical-align: top;
}

.vtabs .tabs-vertical li .nav-link {
  color: #019e7d;
  margin-bottom: 10px;
  border: 0px;
  border-radius: 4px 0 0 4px;
}

.vtabs .tab-content {
  display: table-cell;
  padding: 20px;
  vertical-align: top;
}

.tabs-vertical li .nav-link.active,
.tabs-vertical li .nav-link:hover,
.tabs-vertical li .nav-link.active:focus {
  background: #019e7d;
  border: 0px;
  color: #ffffff;
}

/*Custom vertical tab*/
.customvtab .tabs-vertical li .nav-link.active,
.customvtab .tabs-vertical li .nav-link:hover,
.customvtab .tabs-vertical li .nav-link:focus {
  background: #ffffff;
  border: 0px;
  border-right: 2px solid #019e7d;
  margin-right: -1px;
  color: #019e7d;
}

.tabcontent-border {
  border: 1px solid #ddd;
  border-top: 0px;
}

.customtab2 li a.nav-link {
  border: 0px;
  margin-right: 3px;
  color: #019e7d;
}

.customtab2 li a.nav-link.active {
  background: #019e7d;
  color: #ffffff;
}

.customtab2 li a.nav-link:hover {
  color: #ffffff;
  background: #019e7d;
}

/*******************
Timeline page
******************/
.timeline {
  position: relative;
  padding: 20px 0 20px;
  list-style: none;
  max-width: 1200px;
  margin: 0 auto;
}

.timeline:before {
  content: " ";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 3px;
  margin-left: -1.5px;
  background-color: #f4f8fa;
}

.timeline>li {
  position: relative;
  margin-bottom: 20px;
}

.timeline>li:before,
.timeline>li:after {
  content: " ";
  display: table;
}

.timeline>li:after {
  clear: both;
}

.timeline>li:before,
.timeline>li:after {
  content: " ";
  display: table;
}

.timeline>li:after {
  clear: both;
}

.timeline>li>.timeline-panel {
  float: left;
  position: relative;
  width: 46%;
  padding: 20px;
  border: 1px solid rgba(120, 130, 140, 0.13);
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
}

.timeline>li>.timeline-panel:before {
  content: " ";
  display: inline-block;
  position: absolute;
  top: 26px;
  right: -8px;
  border-top: 8px solid transparent;
  border-right: 0 solid rgba(120, 130, 140, 0.13);
  border-bottom: 8px solid transparent;
  border-left: 8px solid rgba(120, 130, 140, 0.13);
}

.timeline>li>.timeline-panel:after {
  content: " ";
  display: inline-block;
  position: absolute;
  top: 27px;
  right: -7px;
  border-top: 7px solid transparent;
  border-right: 0 solid #ffffff;
  border-bottom: 7px solid transparent;
  border-left: 7px solid #ffffff;
}

.timeline>li>.timeline-badge {
  z-index: 10;
  position: absolute;
  top: 16px;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-left: -25px;
  border-radius: 50% 50% 50% 50%;
  text-align: center;
  font-size: 1.4em;
  line-height: 46px;
  color: #fff;
  overflow: hidden;
}

.timeline>li.timeline-inverted>.timeline-panel {
  float: right;
}

.timeline>li.timeline-inverted>.timeline-panel:before {
  right: auto;
  left: -8px;
  border-right-width: 8px;
  border-left-width: 0;
}

.timeline>li.timeline-inverted>.timeline-panel:after {
  right: auto;
  left: -7px;
  border-right-width: 7px;
  border-left-width: 0;
}

.timeline-badge.primary {
  background-color: #019e7d;
}

.timeline-badge.success {
  background-color: #2cdd9b;
}

.timeline-badge.warning {
  background-color: #fec500;
}

.timeline-badge.danger {
  background-color: #ff4d7e;
}

.timeline-badge.info {
  background-color: #188ef4;
}

.timeline-title {
  margin-top: 0;
  color: inherit;
  font-weight: 400;
}

.timeline-body>p,
.timeline-body>ul {
  margin-bottom: 0;
}

.timeline-body>p+p {
  margin-top: 5px;
}

/*============================================================== 
 Ui-bootstrap
 ============================================================== */
ul.list-icons {
  margin: 0px;
  padding: 0px;
}

ul.list-icons li {
  list-style: none;
  line-height: 30px;
  margin: 5px 0;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

ul.list-icons li a {
  color: #8d97ad;
}

ul.list-icons li a:hover {
  color: #019e7d;
}

ul.list-icons li i {
  font-size: 13px;
  padding-right: 8px;
}

ul.list-inline li {
  display: inline-block;
}

ul.list-inline li:first-child {
  padding-left: 0px;
}

ul.list-inline li a {
  color: #8d97ad;
}

ul.list-inline li a:hover {
  color: #019e7d;
}

ul.two-part {
  margin: 0px;
}

ul.two-part li {
  width: 48.8%;
}

/*Accordion*/
html body .accordion .card {
  margin-bottom: 0px;
}

#accordion4 .card-header {
  border: 1px solid rgba(120, 130, 140, 0.13);
  background: transparent;
  margin-bottom: 10px;
}

#accordion4 .card-header a {
  color: #8d97ad;
}

#accordion4 .card-header:hover {
  background-color: #188ef4;
  border: 1px solid #188ef4;
}

#accordion4 .card-header:hover a {
  color: #ffffff;
}

#accordion4 .card.active .card-header {
  background-color: #188ef4;
  border: 1px solid #188ef4;
}

#accordion4 .card.active .card-header a {
  color: #ffffff;
}

/*===================== 
Breadcrumb
=====================*/
.bc-colored .breadcrumb-item,
.bc-colored .breadcrumb-item a {
  color: #ffffff;
}

.bc-colored .breadcrumb-item.active,
.bc-colored .breadcrumb-item a.active {
  opacity: 0.7;
}

.bc-colored .breadcrumb-item+.breadcrumb-item::before {
  color: rgba(255, 255, 255, 0.4);
}

.breadcrumb {
  margin-bottom: 0px;
}

/*******************
 Card title
*******************/
.card-title {
  font-size: 18px;
}

.card-subtitle {
  color: #8d97ad;
  margin-bottom: 30px;
  font-size: 14px;
}

.card-inverse .card-bodyquote .blockquote-footer,
.card-inverse .card-link,
.card-inverse .card-subtitle,
.card-inverse .card-text {
  color: rgba(255, 255, 255, 0.65);
}

.card-default .card-header {
  background: #ffffff;
  border-bottom: 0px;
}

.card-success {
  background: #2cdd9b;
  border-color: #2cdd9b;
}

.card-danger {
  background: #ff4d7e;
  border-color: #ff4d7e;
}

.card-warning {
  background: #fec500;
  border-color: #fec500;
}

.card-info {
  background: #188ef4;
  border-color: #188ef4;
}

.card-primary {
  background: #019e7d;
  border-color: #019e7d;
}

.card-dark {
  background: #3e4555;
  border-color: #3e4555;
}

.card-megna {
  background: #1dc8cd;
  border-color: #1dc8cd;
}

/*============================================================== 
 Cards page
 ============================================================== */
.card-columns .card {
  margin-bottom: 20px;
}

.collapsing {
  -webkit-transition: height .08s ease;
  -o-transition: height .08s ease;
  transition: height .08s ease;
}

.card-info {
  background: #188ef4;
  border-color: #188ef4;
}

.card-primary {
  background: #019e7d;
  border-color: #019e7d;
}

.card-outline-info {
  border-color: #188ef4;
}

.card-outline-info .card-header {
  background: #188ef4;
  border-color: #188ef4;
}

.card-outline-inverse {
  border-color: #3e4555;
}

.card-outline-inverse .card-header {
  background: #3e4555;
  border-color: #3e4555;
}

.card-outline-warning {
  border-color: #fec500;
}

.card-outline-warning .card-header {
  background: #fec500;
  border-color: #fec500;
}

.card-outline-success {
  border-color: #2cdd9b;
}

.card-outline-success .card-header {
  background: #2cdd9b;
  border-color: #2cdd9b;
}

.card-outline-danger {
  border-color: #ff4d7e;
}

.card-outline-danger .card-header {
  background: #ff4d7e;
  border-color: #ff4d7e;
}

.card-outline-primary {
  border-color: #019e7d;
}

.card-outline-primary .card-header {
  background: #019e7d;
  border-color: #019e7d;
}

/*******************
Custom-select
******************/
.custom-select {
  background: url(../../images/ui/custom-select.png) right 0.75rem center no-repeat;
  border: 2px solid rgba(120, 130, 140, 0.13);
  height: calc(2.25rem + 12px);
}

/*******************
textarea
******************/
textarea {
  resize: none;
}

.form-control-danger,
.form-control-success,
.form-control-warning {
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right .5625rem;
  -webkit-background-size: 1.125rem 1.125rem;
  background-size: 1.125rem 1.125rem;
}

.has-success .col-form-label,
.has-success .custom-control,
.has-success .form-check-label,
.has-success .form-control-feedback,
.has-success .form-control-label {
  color: #2cdd9b;
}

.has-success .form-control-success {
  background-image: url(../../images/ui/success.svg);
}

.has-success .form-control {
  border-color: #2cdd9b;
}

.has-warning .col-form-label,
.has-warning .custom-control,
.has-warning .form-check-label,
.has-warning .form-control-feedback,
.has-warning .form-control-label {
  color: #fec500;
}

.has-warning .form-control-warning {
  background-image: url(../../images/ui/warning.svg);
}

.has-warning .form-control {
  border-color: #fec500;
}

.has-danger .col-form-label,
.has-danger .custom-control,
.has-danger .form-check-label,
.has-danger .form-control-feedback,
.has-danger .form-control-label {
  color: #ff4d7e;
}

.has-danger .form-control-danger {
  background-image: url(../../images/ui/danger.svg);
}

.has-danger .form-control {
  border-color: #ff4d7e;
}

.input-group-addon [type="radio"]:not(:checked),
.input-group-addon [type="radio"]:checked,
.input-group-addon [type="checkbox"]:not(:checked),
.input-group-addon [type="checkbox"]:checked {
  position: initial;
  opacity: 1;
}

.input-form .btn {
  padding: 14px 12px;
}

.form-control-sm {
  padding: .25rem .5rem;
}

/*******************
custom modals
******************/
.custom-modal .close-btn {
  position: absolute;
  right: 12px;
  top: 10px;
  color: #8d97ad;
  font-size: 25px;
  background: #ffffff;
  width: 20px;
  height: 20px;
  text-align: center;
  display: block;
  border-radius: 100%;
  line-height: 19px;
}

.custom-modal .modal-body {
  overflow: hidden;
}

.custom-modal .modal-bg-img {
  background-size: cover;
  background-position: center center;
  min-height: 300px;
}

.modal1 .modal-bg {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;
  padding: 40px;
}

.modal1 .input-group {
  margin-top: -45px;
}

.modal2 .modal-bg {
  padding: 30px 30px 30px 15px;
}

.modal3 .modal-bg {
  padding: 50px 40px 60px 25px;
}

.modal4 .modal-bg {
  padding: 55px 55px 55px 40px;
}

/*******************
Overlay headers and thrid dropdown
******************/
.header-overlay {
  position: absolute;
  width: 100%;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #019e7d;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-item>.ml-auto {
  position: absolute;
  right: 15px;
  top: 10px;
}

.dropdown-submenu>.dropdown-menu {
  top: 0;
  left: 100%;
  margin-left: 0;
  border-radius: 0.25rem;
  display: none;
}

.dropdown-submenu>.dropdown-menu.menu-right {
  left: auto;
  right: 100%;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
  left: -75%;
}

.dropdown-menu .divider {
  background-color: rgba(120, 130, 140, 0.13);
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
}

/*******************
common-innerpage-banner
*******************/
.banner-innerpage {
  padding: 150px 0 100px;
  background-size: cover;
  background-position: center center;
}

.banner-innerpage .title {
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
}

.banner-innerpage .subtitle {
  color: #ffffff;
}

@media (max-width: 900px) {
  .static-slider10 .title {
    font-size: 40px;
    line-height: 45px;
  }
}

.with-noborder .media {
  border: 0px;
  padding: 0px;
  margin: 50px 0;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #019e7d;
}

/*******************
Footer 4
*******************/
.footer4 .f4-bottom-bar {
  padding-top: 10px;
  margin-top: 20px;
}

.footer4 .f4-bottom-bar a {
  color: #8d97ad;
}

.footer4 .f4-bottom-bar a:hover {
  color: #188ef4;
}

.footer4 .round-social.light a {
  width: 34px;
  height: 34px;
  line-height: 34px;
}

.footer4 .round-social.light a:hover {
  background: #188ef4;
}

/*******************
Modal in center
*******************/
.modal-dialog {
  min-height: calc(100vh - 60px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: auto;
}

@media (max-width: 768px) {
  .modal-dialog {
    min-height: calc(100vh - 20px);
  }
}

.animated {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

/*******************
Icons
*******************/
.icon-list-demo div {
  cursor: pointer;
  line-height: 60px;
  white-space: nowrap;
}

.icon-list-demo div p {
  margin: 10px 0;
  padding: 5px 0;
}

.icon-list-demo div:hover {
  color: #263238;
}

.icon-list-demo div:hover i {
  font-size: 2em;
}

.icon-list-demo i {
  -webkit-transition: all 0.2s;
  -webkit-transition: font-size .2s;
  display: inline-block;
  font-size: 18px;
  margin: 0 15px 0 10px;
  text-align: left;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  -o-transition: font-size .2s;
  transition: font-size .2s;
  vertical-align: middle;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.icon-list-demo .col-md-4,
.icon-list-demo .col-3 {
  border-radius: 4px;
}

.client2 {
  padding: 0px 0px 50px 0px;
}

.client2 .client-box {
  margin: 0px;
}

.client2 .client-box [class*="col-"] {
  padding-top: 10px;
  padding-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.fixed-header .transparent-header2 {
  background-color: #27b1e4;
}

.fixed-header .transparent-header {
  background-color: #019e7d;
}

.fixed-header .header18,
.fixed-header .tansparent-dark-header {
  background-color: #ffffff;
}

/*******************
Header 1
*******************/
.header1 {
  background-color: #ffffff;
}

.header1 .h1-nav {
  padding: 15px 0;
}

.header1 .h1-nav .navbar-nav .nav-item {
  margin: 0 20px;
}

.header1 .h1-nav .navbar-nav .nav-item .nav-link {
  padding: 12px 0px;
  color: #019e7d;
  font-weight: 400;
}

.header1 .h1-nav .navbar-nav .nav-item .nav-link:hover {
  color: #019e7d;
}

.header1 .h1-nav .navbar-nav .nav-item:last-child {
  margin-right: 0px;
}

/*******************
Header 2
*******************/
.h2-nav {
  width: 100%;
  z-index: 1;
  left: 0px;
  padding: 25px 15px;
}

.h2-nav .navbar-nav .nav-item {
  margin: 0 15px;
}

.h2-nav .navbar-nav .nav-item .nav-link {
  padding: 12px 0px;
  color: #ffffff;
  font-weight: 400;
}

.h2-nav .navbar-nav .nav-item .nav-link:hover {
  color: #019e7d;
}

.h2-nav .navbar-nav .nav-item .btn {
  opacity: 0.5;
}

.h2-nav .navbar-nav .nav-item .btn:hover {
  opacity: 1;
}

@media (max-width: 1023px) {
  .h2-nav .navbar-collapse {
    background: #2cdd9b;
    background: -webkit-linear-gradient(legacy-direction(to right), #2cdd9b 0%, #1dc8cc 100%);
    background: -webkit-gradient(linear, left top, right top, from(#2cdd9b), to(#1dc8cc));
    background: -webkit-linear-gradient(left, #2cdd9b 0%, #1dc8cc 100%);
    background: -o-linear-gradient(left, #2cdd9b 0%, #1dc8cc 100%);
    background: linear-gradient(to right, #2cdd9b 0%, #1dc8cc 100%);
    padding: 15px 15px 20px;
    border-radius: 4px;
    -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
    box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
  }

  .h2-nav .dropdown-menu,
  .h2-nav .dropdown-item:focus,
  .h2-nav .dropdown-item:hover {
    background: #2cdd9b;
    background: -webkit-linear-gradient(legacy-direction(to right), #2cdd9b 0%, #1dc8cc 100%);
    background: -webkit-gradient(linear, left top, right top, from(#2cdd9b), to(#1dc8cc));
    background: -webkit-linear-gradient(left, #2cdd9b 0%, #1dc8cc 100%);
    background: -o-linear-gradient(left, #2cdd9b 0%, #1dc8cc 100%);
    background: linear-gradient(to right, #2cdd9b 0%, #1dc8cc 100%);
    border: 0px;
  }

  .h2-nav .dropdown-menu .dropdown-item,
  .h2-nav .dropdown-item:focus .dropdown-item,
  .h2-nav .dropdown-item:hover .dropdown-item {
    color: #ffffff;
  }
}

/*******************
Header 3
*******************/
.header3 {
  position: relative;
  z-index: 10;
  background-color: #ffffff;
}

.header3 .h3-topbar {
  padding: 10px 0;
}

.header3 .h3-topbar .list-inline {
  margin-bottom: 0px;
}

.header3 .h3-topbar .list-inline li {
  display: inline-block;
}

.header3 .h3-topbar .list-inline li a {
  padding: 10px 8px;
  display: block;
  color: #8d97ad;
  font-weight: 400;
  font-size: 14px;
}

.header3 .h3-topbar .list-inline li a:hover {
  color: #019e7d;
}

.header3 .h3-topbar .list-inline li a .info-icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  font-size: 25px;
}

.header3 .h3-topbar .list-inline li a .vdevider {
  width: 1px;
  background: rgba(120, 130, 140, 0.13);
  display: inline-block;
  height: 25px;
  vertical-align: middle;
}

.header3 .h3-topbar .t-r {
  text-align: right;
}

.header3 .h3-navbar {
  border-top: 1px solid rgba(120, 130, 140, 0.13);
  position: relative;
}

.header3 .h3-navbar .navbar-brand {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 150px;
  text-align: center;
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
  box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
  padding: 0;
  /*top: -44px;*/
  top: 15px;
}

.header3 .h3-navbar .navbar {
  padding: 0px;
  margin-top: -1px;
}

.header3 .h3-navbar .navbar .navbar-nav .nav-item {
  margin: 0 10px;
}

.header3 .h3-navbar .navbar .navbar-nav .nav-link {
  padding: 22px 20px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  color: #8d97ad;
  border-top: 1px solid transparent;
}

.header3 .h3-navbar .navbar .navbar-nav .nav-item.active .nav-link,
.header3 .h3-navbar .navbar .navbar-nav .nav-item:hover .nav-link {
  border-top: 1px solid #019e7d;
  color: #019e7d;
}

@media (max-width: 992px) {
  .header3 .h3-navbar .navbar {
    padding: 24px 0;
  }
}

@media (max-width: 1023px) {
  /*.header3 .h3-topbar .t-r {
    text-align: left;
  }

  .header3 .h3-navbar .navbar-brand {
    position: relative;
    top: 0px;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: auto;
    background: transparent;
    padding: 10px 0;
    margin-left: 0px;
  }

  .header3 .h3-navbar .navbar-brand .spare-text {
    display: none;
  }

  .header3 .h3-navbar .navbar .navbar-nav .nav-link {
    border-top: 0px;
    border-left: 1px solid transparent;
  }

  .header3 .h3-navbar .navbar .navbar-nav .nav-item.active .nav-link,
  .header3 .h3-navbar .navbar .navbar-nav .nav-item:hover .nav-link {
    border-top: 0px solid #019e7d;
    border-left: 1px solid #019e7d;
  }*/
}

/*******************
Header 4
*******************/
.h4-topbar {
  background: #2cdd9b;
  background: -webkit-linear-gradient(legacy-direction(to right), #2cdd9b 0%, #1dc8cc 100%);
  background: -webkit-gradient(linear, left top, right top, from(#2cdd9b), to(#1dc8cc));
  background: -webkit-linear-gradient(left, #2cdd9b 0%, #1dc8cc 100%);
  background: -o-linear-gradient(left, #2cdd9b 0%, #1dc8cc 100%);
  background: linear-gradient(to right, #2cdd9b 0%, #1dc8cc 100%);
}

.h4-topbar .navbar {
  padding: 0;
}

.h4-topbar .navbar .hidden-lg-up {
  padding: 15px;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
}

.h4-topbar .navbar .navbar-nav .nav-link {
  padding: 15px;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
}

.h4-topbar .navbar .navbar-nav .nav-item:hover .nav-link,
.h4-topbar .navbar .navbar-nav .nav-item.active .nav-link {
  background: #ffffff;
  color: #263238;
}

.h4-topbar .navbar .navbar-nav .search .nav-link {
  background: rgba(0, 0, 0, 0.05);
  padding: 15px 23px;
}

.h4-topbar .navbar .navbar-nav .search .dropdown-menu {
  padding: 20px;
  width: 300px;
}

.h4-nav-bar {
  padding: 15px 0px;
}

.h4-nav-bar .donate-btn a {
  font-size: 13px;
  font-weight: 500;
  padding: 13px 25px;
  margin-left: 15px;
}

.h4-nav-bar .nav-item .nav-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 25px;
}

@media (max-width: 1023px) {
  .h4-nav-bar .donate-btn a {
    display: block;
    margin-left: 0px;
  }

  .h4-nav-bar .nav-item .nav-link {
    padding: 0 10px;
    margin: 10px 0;
  }
}

/*******************
Header 5
*******************/
.h5-nav-bar {
  padding: 25px 15px;
  position: relative;
  width: 100%;
  left: 0px;
}

.h5-nav-bar .navbar-brand {
  position: absolute;
}

.h5-nav-bar .navbar-nav .nav-link {
  color: #ffffff;
  font-size: 14px;
  padding: 25px 18px;
  font-weight: 500;
}

.h5-nav-bar .navbar-nav .nav-item:hover .nav-link {
  color: #019e7d;
}

.h5-nav-bar .rounded-button {
  border-radius: 60px;
  padding: 10px 30px;
  display: inline-block;
  border: 2px solid #019e7d;
  position: absolute;
  right: 0px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
}

.h5-nav-bar .rounded-button a {
  color: #ffffff;
  font-weight: 500;
}

.h5-nav-bar .rounded-button a:hover {
  color: #019e7d;
}

@media (max-width: 1023px) {
  .h5-nav-bar .navbar-brand {
    position: relative;
  }

  .h5-nav-bar .navbar-collapse {
    background: #263238;
    padding: 15px 15px 20px;
    border-radius: 4px;
    -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
    box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
    z-index: 10;
  }

  .h5-nav-bar .rounded-button {
    position: relative;
  }
}

/*******************
Header 6
*******************/
.header6 {
  background-color: #ffffff;
}

.header6 .h6-topbar .authentication-box {
  margin-bottom: 0px;
  padding: 10px 0;
}

.header6 .h6-topbar .authentication-box li {
  display: inline-block;
}

.header6 .h6-topbar .authentication-box li a {
  padding: 3px 15px;
  display: block;
  color: #8d97ad;
  font-weight: 400;
  border-bottom: 0px !important;
}

.header6 .h6-topbar .authentication-box li a:hover {
  color: #188ef4;
}

.header6 .h6-topbar .authentication-box li a:last-child {
  padding-right: 0px;
}

.header6 .h6-topbar .authentication-box li i {
  font-size: 20px;
  vertical-align: text-bottom;
}

.header6 .h6-nav-bar {
  padding: 0px;
}

.header6 .h6-nav-bar .navbar-nav .nav-link {
  padding: 35px 15px;
  color: #8d97ad;
}

.header6 .h6-nav-bar .navbar-nav .nav-item:hover .nav-link,
.header6 .h6-nav-bar .navbar-nav .nav-item.active .nav-link {
  color: #263238;
}

.header6 .h6-nav-bar .act-buttons .btn {
  margin: 10px 0 10px 10px;
}

@media (max-width: 1023px) {
  .header6 .h6-nav-bar .navbar-nav .nav-link {
    padding: 15px 15px;
  }

  .header6 .h6-nav-bar .act-buttons .btn {
    margin: 10px 0 10px 0px;
  }
}

/*******************
Header 7
*******************/
.header7 {
  background: #3e4555;
  max-height: 120px;
}

.header7 .h7-topbar {
  padding: 15px 0;
  color: #ffffff;
}

.header7 .con-btn a {
  padding: 5px 20px;
  text-transform: uppercase;
}

.header7 .h7-nav-bar {
  background: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
  box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
}

.header7 .h7-nav-bar .logo-box {
  padding: 20px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 230px;
  border-right: 1px solid rgba(120, 130, 140, 0.13);
}

.header7 .h7-nav-bar .h7-nav-box {
  display: block;
  width: calc(100% - 230px);
}

.header7 .h7-nav-bar .h7-nav-box .h7-mini-bar {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}

.header7 .h7-nav-bar .h7-nav-box .h7-mini-bar .gen-info span {
  display: inline-block;
  padding: 15px 20px;
  font-weight: 400;
}

.header7 .h7-nav-bar .h7-nav-box .h7-mini-bar .social-info {
  padding-right: 10px;
}

.header7 .h7-nav-bar .h7-nav-box .h7-mini-bar .social-info a {
  display: inline-block;
  padding: 15px 8px;
  font-weight: 400;
  color: #8d97ad;
}

.header7 .h7-nav-bar .h7-nav-box .h7-mini-bar .social-info a:hover {
  color: #263238;
}

.header7 .h7-nav-bar .h7-nav-box .main-nav ul {
  margin: 0px;
  padding: 0px;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
}

.header7 .h7-nav-bar .h7-nav-box .main-nav ul li {
  list-style: none;
  margin: 0px;
  padding: 0px;
  position: relative;
}

.header7 .h7-nav-bar .h7-nav-box .main-nav ul li a {
  padding: 20px 0px 21px;
  color: #8d97ad;
  display: block;
}

.header7 .h7-nav-bar .h7-nav-box .main-nav>ul {
  display: table;
  width: 100%;
}

.header7 .h7-nav-bar .h7-nav-box .main-nav>ul>li {
  display: table-cell;
  text-align: center;
  border-left: 1px solid rgba(120, 130, 140, 0.13);
}

.header7 .h7-nav-bar .h7-nav-box .main-nav>ul>li:first-child {
  border-left: 0px;
}

.header7 .h7-nav-bar .h7-nav-box .main-nav>ul>li:hover {
  background: #f4f8fa;
}

.header7 .h7-nav-bar .h7-nav-box .main-nav>ul>li:hover>ul {
  display: block;
}

.header7 .h7-nav-bar .h7-nav-box .main-nav>ul>li ul {
  position: absolute;
  display: none;
  background: #f4f8fa;
  text-align: left;
  padding: 18px;
  width: 250px;
}

.header7 .h7-nav-bar .h7-nav-box .main-nav>ul>li ul li a {
  padding: 13px 10px;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.header7 .h7-nav-bar .h7-nav-box .main-nav>ul>li ul li a:hover {
  padding: 13px 10px 13px 18px;
  color: #263238;
}

@media (max-width: 767px) {
  .header7 .h7-nav-bar {
    display: block;
  }

  .header7 .h7-nav-bar .logo-box {
    display: inline-block;
  }

  .header7 .h7-nav-bar .h7-nav-box {
    display: none;
    width: 100%;
  }

  .header7 .h7-nav-bar .h7-nav-box.show {
    display: block;
  }

  .header7 .h7-nav-bar .h7-nav-box .h7-mini-bar {
    border-top: 1px solid rgba(120, 130, 140, 0.13);
  }

  .header7 .h7-nav-bar .h7-nav-box .main-nav {
    max-height: 200px;
    overflow: auto;
  }

  .header7 .h7-nav-bar .h7-nav-box .main-nav>ul {
    display: block;
  }

  .header7 .h7-nav-bar .h7-nav-box .main-nav>ul>li {
    display: block;
    text-align: left;
  }

  .header7 .h7-nav-bar .h7-nav-box .main-nav>ul>li a {
    padding: 20px;
  }

  .header7 .h7-nav-bar .h7-nav-box .main-nav>ul>li ul {
    position: relative;
    width: 100%;
    padding-top: 0px;
  }

  .header7 .h7-nav-bar .op-clo {
    padding: 20px 22px;
    float: right;
    margin: 25px 30px 0 0;
  }
}

/*******************
Header 11
*******************/
.header11 {
  background-color: #ffffff;
}

.header11 .h11-nav {
  padding: 0;
}

.header11 .h11-nav .navbar-brand {
  padding: 10px 0;
}

.header11 .h11-nav .h11-topbar ul {
  margin-bottom: 0px;
  padding: 15px 0;
}

.header11 .h11-nav .h11-topbar ul li {
  display: inline-block;
  vertical-align: middle;
}

.header11 .h11-nav .h11-topbar ul li a {
  padding: 0px 7px;
  color: #8d97ad;
  display: block;
}

.header11 .h11-nav .h11-topbar ul li:hover a {
  color: #263238;
}

.header11 .h11-nav .h11-topbar ul li:last-child a {
  font-size: 21px;
  font-weight: 600;
  color: #263238;
  padding-left: 15px;
  margin-left: 7px;
  border-left: 1px solid rgba(120, 130, 140, 0.13);
}

.header11 .h11-nav .navbar-nav .nav-item {
  margin: 0 20px;
}

.header11 .h11-nav .navbar-nav .nav-item .nav-link {
  padding: 12px 0px;
  color: #8d97ad;
  border-bottom: 1px solid transparent;
  font-weight: 500;
  text-transform: uppercase;
}

.header11 .h11-nav .navbar-nav .nav-item:hover .nav-link,
.header11 .h11-nav .navbar-nav .nav-item.active .nav-link {
  color: #188ef4;
  border-bottom: 1px solid #188ef4;
}

.header11 .h11-nav .navbar-nav .nav-item:last-child {
  margin-right: 0px;
}

@media (max-width: 1023px) {
  .header11 .h11-nav .navbar-nav .nav-item {
    margin: 0;
  }
}

/*******************
Header 12
*******************/
.header12 {
  background: #263238;
}

.header12 .h12-nav {
  padding: 15px 0;
}

.header12 .h12-nav .navbar-nav .nav-item .nav-link {
  color: #8d97ad;
  padding: 10px 15px;
  display: block;
}

.header12 .h12-nav .navbar-nav .nav-item:hover .nav-link,
.header12 .h12-nav .navbar-nav .nav-item.active .nav-link {
  color: #ffffff;
}

.header12 .h12-nav .navbar-nav .nav-item:last-child {
  margin-left: 10px;
}

.header12 .h12-topbar {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}

.header12 .h12-topbar .navbar-brand {
  font-size: 14px;
  color: #ffffff;
  padding: 15px 0;
}

.header12 .h12-topbar .navbar {
  padding: 0;
}

.header12 .h12-topbar .navbar .nav-item .nav-link {
  color: #8d97ad;
  padding: 15px 20px;
  border-right: 1px solid rgba(120, 130, 140, 0.13);
}

.header12 .h12-topbar .navbar .nav-item .nav-link:first-child {
  border-left: 1px solid rgba(120, 130, 140, 0.13);
}

.header12 .h12-topbar .navbar .nav-item:hover .nav-link {
  color: #ffffff;
}

/*******************
Header 13
*******************/
.header13 {
  background-color: #ffffff;
}

.header13 .h13-nav {
  padding: 15px 0;
}

.header13 .h13-nav .call-info {
  padding-left: 20px;
  border-left: 1px solid rgba(120, 130, 140, 0.13);
}

.header13 .h13-nav .navbar-nav .nav-item .nav-link {
  color: #8d97ad;
  padding: 10px 15px;
  display: block;
}

.header13 .h13-nav .navbar-nav .nav-item:hover .nav-link,
.header13 .h13-nav .navbar-nav .nav-item.active .nav-link {
  color: #ff4d7e;
}

.header13 .h13-nav .navbar-nav .nav-item:last-child {
  margin-left: 10px;
}

.header13 .h13-topbar {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}

.header13 .h13-topbar .navbar-brand {
  font-size: 14px;
  color: #188ef4;
  padding: 15px 0;
}

.header13 .h13-topbar .navbar {
  padding: 0;
}

.header13 .h13-topbar .navbar .search-box {
  padding: 15px;
  width: 240px;
}

.header13 .h13-topbar .navbar .nav-item .nav-link {
  color: #8d97ad;
  padding: 15px 20px;
}

.header13 .h13-topbar .navbar .nav-item .nav-link:first-child {
  padding-left: 0px;
}

.header13 .h13-topbar .navbar .nav-item:hover .nav-link {
  color: #ff4d7e;
}

/*******************
Header 14
*******************/
.header14 {
  background-color: #ffffff;
}

.header14 .h14-topbar {
  background: #f4f8fa;
}

.header14 .h14-topbar .navbar-brand {
  font-size: 14px;
  color: #263238;
  font-weight: 500;
  padding: 15px 0;
}

.header14 .h14-topbar .navbar {
  padding: 0;
}

.header14 .h14-topbar .navbar .search-box {
  padding: 15px;
  width: 240px;
}

.header14 .h14-topbar .navbar .nav-item .nav-link {
  color: #8d97ad;
  padding: 15px 20px;
}

.header14 .h14-topbar .navbar .nav-item .nav-link:first-child {
  padding-left: 0px;
}

.header14 .h14-navbar {
  background: #fed700;
}

.header14 .h14-navbar .navbar {
  padding: 0;
}

.header14 .h14-navbar .navbar .hidden-lg-up {
  padding: 15px;
  color: #263238;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
}

.header14 .h14-navbar .navbar .navbar-nav .nav-link {
  padding: 18px 15px;
  color: #263238;
  font-weight: 400;
  font-size: 13px;
  text-transform: uppercase;
}

.header14 .h14-navbar .navbar .navbar-nav .nav-item:hover .nav-link,
.header14 .h14-navbar .navbar .navbar-nav .nav-item.active .nav-link {
  background: rgba(120, 130, 140, 0.13);
  color: #263238;
}

.header14 .h14-navbar .navbar .navbar-nav .search .nav-link {
  background: rgba(0, 0, 0, 0.05);
  padding: 20px 23px;
}

.header14 .h14-navbar .navbar .navbar-nav .search .dropdown-menu {
  padding: 20px;
  min-width: 300px;
}

.header14 .h14-navbar .navbar .dropdown-menu {
  border-radius: 0 0 4px 4px;
  margin-top: 0px;
}

.header14 .h14-navbar .navbar .dropdown-menu .dropdown-item {
  color: #263238;
}

.header14 .h14-navbar .navbar .dropdown-menu .dropdown-item.active,
.header14 .h14-navbar .navbar .dropdown-menu .dropdown-item:active,
.header14 .h14-navbar .navbar .dropdown-menu .dropdown-item:hover {
  background: rgba(120, 130, 140, 0.13);
}

.header14 .h14-info-bar {
  padding: 15px 0px;
}

.header14 .h14-info-bar .donate-btn a {
  font-size: 13px;
  font-weight: 500;
  padding: 13px 25px;
  margin-left: 15px;
}

.header14 .h14-info-bar .nav-item .nav-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 25px;
}

@media (max-width: 1023px) {
  .header14 .h14-info-bar .donate-btn a {
    display: block;
    margin-left: 0px;
  }

  .header14 .h14-info-bar .nav-item .nav-link {
    padding: 0 10px;
    margin: 10px 0;
  }
}

/*******************
Header 15
*******************/
.header15 {
  background-color: #ffffff;
}

.header15 .h15-topbar {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}

.header15 .h15-topbar .navbar-brand {
  font-size: 14px;
  color: #263238;
  font-weight: 500;
  padding: 15px 0;
}

.header15 .h15-topbar .navbar {
  padding: 0;
}

.header15 .h15-topbar .navbar .search-box {
  padding: 15px;
  width: 240px;
}

.header15 .h15-topbar .navbar .nav-item .nav-link {
  color: #8d97ad;
  padding: 15px 20px;
}

.header15 .h15-topbar .navbar .nav-item .nav-link:first-child {
  padding-left: 0px;
}

.header15 .h15-topbar .navbar .nav-item:hover .nav-link {
  color: #188ef4;
}

.header15 .h15-topbar .navbar .search .dropdown-menu {
  padding: 20px;
  min-width: 300px;
}

.header15 .h15-navbar .navbar {
  padding: 0;
}

.header15 .h15-navbar .navbar .hidden-lg-up {
  padding: 15px;
  color: #263238;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
}

.header15 .h15-navbar .navbar .navbar-nav .nav-link {
  padding: 18px 15px;
  color: #263238;
  font-weight: 400;
  font-size: 14px;
  border-bottom: 1px solid transparent;
}

.header15 .h15-navbar .navbar .navbar-nav .nav-item:hover .nav-link,
.header15 .h15-navbar .navbar .navbar-nav .nav-item.active .nav-link {
  color: #188ef4;
  border-bottom: 1px solid #188ef4;
}

.header15 .h15-navbar .navbar .dropdown-menu {
  background: #188ef4;
  border-radius: 0 0 4px 4px;
  margin-top: 0px;
}

.header15 .h15-navbar .navbar .dropdown-menu .dropdown-item {
  color: #ffffff;
}

.header15 .h15-navbar .navbar .dropdown-menu .dropdown-item.active,
.header15 .h15-navbar .navbar .dropdown-menu .dropdown-item:active,
.header15 .h15-navbar .navbar .dropdown-menu .dropdown-item:hover {
  background: rgba(120, 130, 140, 0.13);
}

.header15 .h15-info-bar {
  padding: 15px 0px;
}

.header15 .h15-info-bar .donate-btn a {
  font-size: 13px;
  font-weight: 500;
  padding: 13px 25px;
  margin-left: 15px;
}

.header15 .h15-info-bar .nav-item .nav-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 25px;
}

@media (max-width: 1023px) {
  .header15 .h15-info-bar .donate-btn a {
    display: block;
    margin-left: 0px;
  }

  .header15 .h15-info-bar .nav-item .nav-link {
    padding: 0 10px;
    margin: 10px 0;
  }
}

/*******************
Header 16
*******************/
.h16-topbar {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}

.h16-topbar .navbar-brand {
  font-size: 14px;
  color: #263238;
  font-weight: 500;
  padding: 15px 0;
}

.h16-topbar .navbar {
  padding: 0;
}

.h16-topbar .navbar .search-box {
  padding: 15px;
  width: 240px;
}

.h16-topbar .navbar .nav-item .nav-link {
  color: #8d97ad;
  padding: 15px;
}

.h16-topbar .navbar .navbar-nav .nav-item:first-child .nav-link {
  border-right: 1px solid rgba(120, 130, 140, 0.13);
}

.h16-topbar .navbar .nav-item:hover .nav-link {
  color: #ff4d7e;
}

.h16-topbar .navbar .search .dropdown-menu {
  padding: 20px;
  min-width: 300px;
}

.h16-navbar {
  margin-bottom: -55px;
}

.h16-navbar .navbar {
  background: #ff4d7e;
  background: -webkit-linear-gradient(legacy-direction(to right), #ff4d7e 0%, #ff6a5b 100%);
  background: -webkit-gradient(linear, left top, right top, from(#ff4d7e), to(#ff6a5b));
  background: -webkit-linear-gradient(left, #ff4d7e 0%, #ff6a5b 100%);
  background: -o-linear-gradient(left, #ff4d7e 0%, #ff6a5b 100%);
  background: linear-gradient(to right, #ff4d7e 0%, #ff6a5b 100%);
  padding: 0px;
}

.h16-navbar .navbar .hidden-lg-up {
  padding: 17px;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
}

.h16-navbar .navbar .navbar-nav .nav-link {
  padding: 18px 18px;
  color: #ffffff;
  font-weight: 400;
  font-size: 13px;
  text-transform: uppercase;
  margin-right: 5px;
}

.h16-navbar .navbar .navbar-nav .nav-item:hover .nav-link,
.h16-navbar .navbar .navbar-nav .nav-item.active .nav-link {
  background: rgba(120, 130, 140, 0.13);
}

.h16-navbar .navbar .navbar-nav .search .nav-link {
  background: rgba(0, 0, 0, 0.05);
  color: #ffffff;
  padding: 18px 23px;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
  margin-right: 0px;
}

.h16-navbar .navbar .navbar-nav .search:hover .nav-link {
  color: #ffffff;
  background: #3e4555;
}

.h16-navbar .navbar .navbar-nav .search .dropdown-menu {
  padding: 20px;
  min-width: 300px;
}

.h16-navbar .navbar .dropdown-menu {
  background: #ff4d7e;
  border-radius: 0 0 4px 4px;
  margin-top: 0px;
}

.h16-navbar .navbar .dropdown-menu .dropdown-item {
  color: #ffffff;
}

.h16-navbar .navbar .dropdown-menu .dropdown-item.active,
.h16-navbar .navbar .dropdown-menu .dropdown-item:active,
.h16-navbar .navbar .dropdown-menu .dropdown-item:hover {
  background: rgba(120, 130, 140, 0.13);
}

.h16-info-bar {
  padding: 15px 0px;
}

.h16-info-bar .donate-btn a {
  font-size: 13px;
  font-weight: 500;
  padding: 13px 25px;
  margin-left: 15px;
}

.h16-info-bar .nav-item .nav-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 25px;
}

@media (max-width: 1023px) {
  .h16-info-bar .donate-btn a {
    display: block;
    margin-left: 0px;
  }

  .h16-info-bar .nav-item .nav-link {
    padding: 0 10px;
    margin: 10px 0;
  }
}

/*******************
Header 17
*******************/
.header17 .h17-nav {
  padding: 15px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.header17 .h17-nav .call-info {
  padding-left: 20px;
  border-left: 1px solid rgba(120, 130, 140, 0.13);
  vertical-align: middle;
}

.header17 .h17-nav .h17nav-bar {
  margin-bottom: 0px;
}

.header17 .h17-nav .h17nav-bar li {
  display: inline-block;
  vertical-align: middle;
}

.header17 .h17-nav .h17nav-bar li a.nav-link {
  color: #8d97ad;
  padding: 10px 15px;
  display: block;
}

.header17 .h17-nav .h17nav-bar:hover .nav-link,
.header17 .h17-nav .h17nav-bar.active .nav-link {
  color: #ff4d7e;
}

.header17 .h17-nav .h17nav-bar:last-child {
  margin-left: 10px;
}

.header17 .h17-main-nav {
  z-index: 999;
  overflow: auto;
  top: 0px;
  padding: 60px 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: none;
  background: #3e4555;
}

.header17 .h17-main-nav.show {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.header17 .h17-main-nav .close-icons {
  position: absolute;
  right: 30px;
  top: 30px;
  color: #8d97ad;
}

.header17 .h17-main-nav .close-icons:hover {
  color: #ffffff;
}

.header17 .h17-main-nav ul {
  margin: 0px;
  padding: 0px;
}

.header17 .h17-main-nav ul li {
  list-style: none;
}

.header17 .h17-main-nav ul li a {
  color: #8d97ad;
}

.header17 .h17-main-nav ul li a:hover {
  color: #ffffff;
}

.header17 .h17-main-nav .nav-menu {
  display: inline-block;
}

.header17 .h17-main-nav .nav-menu li a {
  font-size: 24px;
  padding: 10px 0 7px;
  margin-bottom: 5px;
  display: block;
  border-bottom: 2px solid transparent;
}

.header17 .h17-main-nav .nav-menu li:hover a,
.header17 .h17-main-nav .nav-menu li.active a {
  border-bottom: 2px solid #ff4d7e;
  color: #ffffff;
}

.header17 .h17-main-nav .nav-menu li:last-child a,
.header17 .h17-main-nav .nav-menu li:last-child:hover a {
  font-size: 14px;
  color: #ffffff;
  margin-top: 20px;
  padding: 10px 25px;
  border-bottom: 2px solid transparent;
}

.header17 .h17-main-nav .info-nav {
  margin-top: 60px;
}

.header17 .h17-main-nav .info-nav li {
  display: inline-block;
}

.header17 .h17-main-nav .info-nav li a {
  padding: 0 10px;
}

.header17 .h17-main-nav .social-nav li {
  display: inline-block;
}

.header17 .h17-main-nav .social-nav li a {
  padding: 10px;
  display: block;
}

.header17 .h17-main-nav .social-nav li:last-child {
  display: block;
}

@media (max-width: 599px) {
  .h-17navbar {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
}

/*******************
Header 5
*******************/
.header18 {
  position: absolute;
  z-index: 10;
  width: 100%;
}

.header18 .h18-nav-bar {
  padding: 0px 0px;
  width: 100%;
  left: 0px;
}

.header18 .h18-nav-bar .navbar-nav .nav-link {
  color: #263238;
  font-size: 14px;
  padding: 25px 18px;
  font-weight: 500;
  line-height: 60px;
}

.header18 .h18-nav-bar .navbar-nav .nav-item:hover .nav-link {
  color: #019e7d;
}

.header18 .h18-nav-bar .navbar-nav .nav-item>.nav-link {
  text-transform: uppercase;
}

.header18 .h18-nav-bar .rounded-button {
  border-radius: 60px;
  padding: 10px 30px;
  display: inline-block;
  border: 2px solid #263238;
  position: absolute;
  right: 0px;
  font-size: 14px;
}

.header18 .h18-nav-bar .rounded-button a {
  color: #263238;
  font-weight: 500;
}

.header18 .h18-nav-bar .rounded-button a:hover {
  color: #019e7d;
}

@media (max-width: 1023px) {
  .header18 .h18-nav-bar {
    padding: 10px 0px;
  }

  .header18 .h18-nav-bar .navbar-brand {
    position: relative;
  }

  .header18 .h18-nav-bar .navbar-collapse {
    background: #263238;
    padding: 15px 15px 20px;
    border-radius: 4px;
    -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
    box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
    z-index: 10;
  }

  .header18 .h18-nav-bar .rounded-button {
    position: relative;
    border: 2px solid #ffffff;
  }

  .header18 .h18-nav-bar .rounded-button a {
    color: #ffffff;
    font-weight: 500;
  }

  .header18 .h18-nav-bar .rounded-button a:hover {
    color: #019e7d;
  }

  .header18 .h18-nav-bar .navbar-nav .nav-link {
    color: #ffffff;
    line-height: 30px;
  }
}

.transparent-header {
  position: absolute;
  width: 100%;
  z-index: 1;
  left: 0px;
  padding: 25px 0px;
}

.transparent-header .navbar-nav .nav-item {
  margin: 0 15px;
}

.transparent-header .navbar-nav .nav-item .nav-link {
  padding: 12px 0px;
  color: #ffffff;
  font-weight: 400;
  opacity: 1;
}

.transparent-header .navbar-nav .nav-item .nav-link:hover {
  opacity: 0.5;
}

.transparent-header .navbar-nav .nav-item .btn {
  opacity: 0.5;
}

.transparent-header .navbar-nav .nav-item .btn:hover {
  opacity: 1;
}

.transparent-header2 {
  padding: 0px 15px;
  position: absolute;
  width: 100%;
  left: 0px;
}

.transparent-header2 .navbar-brand {
  position: absolute;
}

.transparent-header2 .navbar-nav .nav-link {
  color: #ffffff;
  font-size: 14px;
  padding: 25px 18px;
  font-weight: 500;
  opacity: 0.7;
  line-height: 50px;
}

.transparent-header2 .navbar-nav .nav-item:hover .nav-link {
  opacity: 1;
}

.transparent-header2 .rounded-button {
  border-radius: 60px;
  padding: 10px 30px;
  display: inline-block;
  border: 2px solid rgba(255, 255, 255, 0.7);
  position: absolute;
  right: 0px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
}

.transparent-header2 .rounded-button a {
  color: #ffffff;
  font-weight: 500;
  opacity: 0.7;
}

.transparent-header2 .rounded-button a:hover {
  opacity: 1;
}

.tansparent-dark-header {
  position: absolute;
  width: 100%;
}

.tansparent-dark-header .trans-nav {
  padding: 15px 0;
}

.tansparent-dark-header .trans-nav .navbar-nav .nav-item {
  margin: 0 20px;
}

.tansparent-dark-header .trans-nav .navbar-nav .nav-item .nav-link {
  padding: 12px 0px;
  color: #8d97ad;
  font-weight: 400;
}

.tansparent-dark-header .trans-nav .navbar-nav .nav-item:hover .nav-link {
  color: #019e7d;
}

.tansparent-dark-header .trans-nav .navbar-nav .nav-item:last-child {
  margin-right: 0px;
}

/*
Template Name: Wrapkit
Author: wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*******************
Feature 1
*******************/
.wrap-feature1-box .card-body {
  padding: 40px;
}

/*******************
Feature 2
*******************/
.wrap-feature2-box .card-body {
  padding: 40px;
}

.feature2 .card.card-shadow {
  margin-bottom: 0;
}

/*******************
Feature 3
*******************/
.wrap-feature3-box .card-body {
  padding: 40px;
}

.wrap-feature3-box .card-body .icon-space {
  padding: 0px 40px 20px 0px;
}

/*******************
Feature 4
*******************/
.wrap-feature4-box .icon-round {
  font-size: 45px;
  width: 80px;
  color: #019e7d;
  line-height: 80px;
  text-align: center;
  display: inline-block;
  margin: 20px 0 30px;
  border-radius: 100%;
}

/*******************
Feature 5
*******************/
.wrap-feature5-box .card-body {
  padding: 30px;
}

.wrap-feature5-box .card-body .icon-space {
  padding: 0px 30px 20px 0px;
  font-size: 45px;
  margin: 0px;
}

.wrap-feature5-box .card-body p {
  margin-bottom: 0px;
}

/*******************
Feature 6
*******************/
.wrap-feature6-box {
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.wrap-feature6-box .card-body {
  padding: 30px;
}

.wrap-feature6-box .card-body .linking {
  color: #ffffff;
}

.wrap-feature6-box .card-body .linking:hover {
  color: #263238;
}

.wrap-feature6-box .card-body p {
  opacity: 0.8;
}

.wrap-feature6-box:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

/*******************
Feature 7
*******************/
.wrap-feature7-box {
  margin-bottom: 30px;
}

/*******************
Feature 8
*******************/
.feature8 .list-block li {
  font-weight: 400;
  color: #263238;
}

/*******************
Feature 9
*******************/
.wrap-feature9-box .card-body {
  padding: 40px 40px 0px;
}

.wrap-feature9-box .card-body .icon-space {
  padding: 0px 40px 20px 0px;
}

/*******************
Feature 10
*******************/
.wrap-feature10-box {
  padding: 100px 20px;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  color: #ffffff;
}

.wrap-feature10-box p {
  margin: 30px 0 40px;
}

.wrap-feature10-box .box-title {
  color: #ffffff;
}

/*******************
Feature 11
*******************/
.wrap-feature11-box {
  margin-top: 60px;
}

.wrap-feature11-box .card-body {
  padding: 40px;
}

.wrap-feature11-box .icon-space {
  margin: -80px 0 20px;
}

.wrap-feature11-box .icon-space .icon-round {
  font-size: 45px;
  color: #ffffff;
}

/*******************
Feature 12
*******************/
.wrap-feature-12 {
  position: relative;
}

.wrap-feature-12 [class*=col-md-12] {
  margin-bottom: 30px;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.wrap-feature-12 [class*=col-md-12]:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.wrap-feature-12 .uneven-box {
  margin-top: 100px;
}

/*******************
Feature 15
*******************/
.feature15 .quote {
  top: -40px;
  left: -40px;
}

/*******************
Feature 16
*******************/
.wrap-feature-16 [class*=col-] {
  padding: 80px 35px;
}

.wrap-feature-16 .with-bg {
  width: 100%;
  min-height: 200px;
  background-size: cover;
  background-position: center center;
}

.wrap-feature-16 .with-text {
  max-width: 500px;
  margin-left: 80px;
}

/*******************
Feature 17
*******************/
.wrap-feature-17-box.card-group .card {
  margin-bottom: 0px;
}

/*******************
Feature 18
*******************/
.wrap-feature-18 {
  margin-top: 60px;
}

.wrap-feature-18 [class*=col-lg-6] .card {
  overflow: hidden;
}

.wrap-feature-18 .icon-position {
  position: relative;
  background-size: cover;
  background-position: center center;
  min-height: 200px;
}

.wrap-feature-18 .icon-position .icon-round {
  position: absolute;
  right: -38px;
  top: 20px;
}

/*******************
Feature 20
*******************/
.wrap-feature-20 {
  margin-top: 60px;
}

.wrap-feature-20 [class*=col-lg-6] .card {
  overflow: hidden;
}

.wrap-feature-20 .linking {
  width: 100%;
  display: block;
  padding: 35px 0;
}

/*******************
Feature 21
*******************/
.wrap-feature21-box {
  background-size: cover;
  background-attachment: fixed;
  background-position: left center;
}

/*******************
Feature 22
*******************/
.wrap-feature-22 {
  margin-top: 60px;
}

.wrap-feature-22 .text-box {
  padding: 0 30px;
}

.wrap-feature-22 .text-box h3 {
  margin: 10px 0 25px 0;
}

/*******************
Feature 23
*******************/
.wrap-feature23-box {
  background-size: cover;
  background-attachment: fixed;
  background-position: left center;
}

.wrap-feature23-box .text-box {
  padding: 30px 20px;
}

/*******************
Feature 24
*******************/
.wrap-feature-24 {
  margin-top: 60px;
}

.wrap-feature-24 .card {
  overflow: hidden;
  -o-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
  -webkit-transition: 0.3s ease-out;
}

.wrap-feature-24 .card:hover {
  -ms-transform: translateY(-10px);
  transform: translateY(-10px);
  -webkit-transform: translateY(-10px);
}

.wrap-feature-24 .service-24 {
  text-align: center;
  padding: 40px 0;
  display: block;
}

.wrap-feature-24 .service-24 i {
  background: #2cdd9b;
  background: -webkit-linear-gradient(legacy-direction(to right), #2cdd9b 0%, #1dc8cc 100%);
  background: -webkit-gradient(linear, left top, right top, from(#2cdd9b), to(#1dc8cc));
  background: -webkit-linear-gradient(left, #2cdd9b 0%, #1dc8cc 100%);
  background: -o-linear-gradient(left, #2cdd9b 0%, #1dc8cc 100%);
  background: linear-gradient(to right, #2cdd9b 0%, #1dc8cc 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  font-size: 50px;
}

.wrap-feature-24 .service-24 .ser-title {
  margin: 10px 0 5px;
  font-weight: 500;
}

.wrap-feature-24 .service-24:hover,
.wrap-feature-24 .service-24:focus {
  background: #2cdd9b;
  background: -webkit-linear-gradient(legacy-direction(to right), #2cdd9b 0%, #1dc8cc 100%);
  background: -webkit-gradient(linear, left top, right top, from(#2cdd9b), to(#1dc8cc));
  background: -webkit-linear-gradient(left, #2cdd9b 0%, #1dc8cc 100%);
  background: -o-linear-gradient(left, #2cdd9b 0%, #1dc8cc 100%);
  background: linear-gradient(to right, #2cdd9b 0%, #1dc8cc 100%);
}

.wrap-feature-24 .service-24:hover i,
.wrap-feature-24 .service-24:hover .ser-title,
.wrap-feature-24 .service-24:focus i,
.wrap-feature-24 .service-24:focus .ser-title {
  color: #ffffff;
  text-fill-color: #ffffff;
  -webkit-text-fill-color: #ffffff;
}

.wrap-feature-24 .service-24:hover i,
.wrap-feature-24 .service-24:focus i {
  margin-bottom: 5px;
}

/*******************
Feature 26
*******************/
.wrap-feature-26 .max-box {
  max-width: 580px;
}

.wrap-feature-28 .side-content {
  max-width: 580px;
}

/*******************
Feature 29
*******************/
.wrap-feature-29 {
  margin-top: 60px;
}

.wrap-feature-29 .title,
.wrap-feature-29 .subtitle {
  color: #ffffff;
}

.wrap-feature-29 .col-lg-4 {
  margin-top: 30px;
}

/*******************
Feature 29
*******************/
.feature30 {
  position: relative;
}

.wrap-feature30-box {
  position: absolute;
  top: 20%;
  right: 0px;
}

/*******************
Feature 31
*******************/
.wrap-feature31-box {
  overflow: hidden;
  position: relative;
}

.wrap-feature31-box .half-radius {
  border-radius: 62% / 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.wrap-feature31-box .right-image {
  position: absolute;
  right: 0px;
  text-align: center;
  bottom: -60px;
}

/*******************
Feature 33
*******************/
.wrap-feature-33 .play-btn {
  position: relative;
}

.wrap-feature-33 .play-btn span.display-1 {
  font-weight: 500;
  opacity: 0.15;
}

.wrap-feature-33 .play-btn a {
  position: absolute;
  left: 40px;
  top: 20px;
  color: #263238;
  font-weight: 500;
}

.wrap-feature-33 .play-btn a span {
  -webkit-box-shadow: 2px 8px 20px rgba(255, 77, 126, 0.5);
  box-shadow: 2px 8px 20px rgba(255, 77, 126, 0.5);
}

.wrap-feature-33 .play-btn a:hover {
  color: #ff4d7e;
}

/*******************
Feature 34
*******************/
.wrap-feature34-box {
  overflow: hidden;
  position: relative;
}

.wrap-feature34-box .left-image {
  position: absolute;
  left: -15px;
  text-align: left;
  top: 0px;
}

/*******************
Feature 35
*******************/
.wrap-feature35-box .feature-35-owl .item {
  position: relative;
}

.wrap-feature35-box .feature-35-owl .item h4 {
  position: absolute;
  bottom: 20px;
  left: 30px;
  color: #ffffff;
  text-transform: uppercase;
}

/*******************
Feature 36
*******************/
.wrap-feature36-box {
  background-position: right top;
  background-repeat: no-repeat;
}

/*******************
Feature 38
*******************/
.wrap-feature38-box .po-absolute {
  right: 0px;
  z-index: 1;
}

/*******************
Feature 39
*******************/
.wrap-feature39-box .col-lg-6>img {
  margin-left: -40px;
}

/*******************
Feature 40
*******************/
.wrap-feature40-box {
  background-position: left top, top right;
  background-repeat: no-repeat, no-repeat;
}

/*******************
Feature 41
*******************/
.wrap-feature41-box .f41-tab {
  display: block;
}

.wrap-feature41-box .f41-tab li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  padding: 25px;
}

.wrap-feature41-box .f41-tab li a .t-icon {
  margin-right: 25px;
  color: #8d97ad;
}

.wrap-feature41-box .f41-tab li a h5 {
  font-weight: 500;
}

.wrap-feature41-box .f41-tab li a h6 {
  font-size: 14px;
  margin-bottom: 0px;
}

.wrap-feature41-box .f41-tab li a.active,
.wrap-feature41-box .f41-tab li a:hover {
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
  box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
}

.wrap-feature41-box .f41-tab li a.active .t-icon,
.wrap-feature41-box .f41-tab li a:hover .t-icon {
  background: #188ef4;
  background: -webkit-linear-gradient(legacy-direction(to right), #188ef4 0%, #019e7d 100%);
  background: -webkit-gradient(linear, left top, right top, from(#188ef4), to(#019e7d));
  background: -webkit-linear-gradient(left, #188ef4 0%, #019e7d 100%);
  background: -o-linear-gradient(left, #188ef4 0%, #019e7d 100%);
  background: linear-gradient(to right, #188ef4 0%, #019e7d 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
}

/*******************
Feature 42
*******************/
.feature42 {
  background-repeat: no-repeat;
  background-size: cover;
}

.feature42 .wrap-feature42-box {
  padding: 15% 0;
}

/*******************
Feature 43
*******************/
.wrap-feature43-box .col-md-6 {
  margin-bottom: 50px;
}

/*******************
Feature 45
*******************/
.wrap-feature45-box {
  margin-top: 60px;
}

.wrap-feature45-box .f45-tab {
  display: table;
  width: 100%;
}

.wrap-feature45-box .f45-tab>a {
  display: table-cell;
  border: 1px solid rgba(120, 130, 140, 0.13);
  text-align: center;
  padding: 15px 10px;
  background: #f4f8fa;
  color: #263238;
}

.wrap-feature45-box .f45-tab>a.active {
  border-bottom-color: transparent;
  background: #ffffff;
  font-weight: 400;
}

.wrap-feature45-box .tab-pane {
  padding: 60px 0;
}

/*******************
Feature 46
*******************/
.feature46 {
  background-repeat: no-repeat;
  background-position: top right;
}

/*******************
Feature 47
*******************/
.wrap-feature47-box .f-47-line {
  line-height: 40px;
}

/*******************
Feature 48
*******************/
.feature48 {
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
}

.feature48 .wrap-feature48-box {
  padding: 10% 0;
}

/*******************
Feature 49
*******************/
/*******************
Static slide 1
*******************/
.static-slider1 h1 {
  font-weight: 700;
  line-height: 56px;
}

.static-slider1 h1>span {
  border-bottom: 3px solid #2cdd9b;
}

@media (max-width: 767px) {
  .static-slider1 h1 {
    font-weight: 700;
    line-height: 36px;
    font-size: 24px;
  }
}

/*******************
Static slide 2
*******************/
.static-slider2 h1 {
  font-size: 90px;
  font-weight: 800;
  line-height: 90px;
}

@media (max-width: 767px) {
  .static-slider2 h1 {
    font-weight: 700;
    line-height: 50px;
    font-size: 50px;
  }
}

/*******************
Static slide 3
*******************/
.static-slider3 {
  padding: 12% 0 10% 0;
  background-size: cover;
}
@media (max-width: 1500px) {
  .static-slider3 {
    background-size: auto;
  }
}

@media (max-width: 992px) {
  .static-slider3 {
    padding: 10% 0;
  }
}

.static-slider3 h1 {
  font-weight: 300;
  line-height: 50px;
}

.static-slider3 h1>span {
  border-bottom: 3px solid #2cdd9b;
}

@media (max-width: 767px) {
  .static-slider3 h1 {
    line-height: 30px;
    font-size: 24px;
  }
}

/*******************
Static slide 4
*******************/
.static-slider4 h1 {
  font-weight: 100;
  line-height: 50px;
}

.static-slider4 h1 b>span {
  border-bottom: 3px solid #188ef4;
}

.static-slider4 .info-box {
  padding: 25px 15px;
}

.static-slider4 .img-anim {
  position: relative;
  top: 110px;
}

@media (max-width: 767px) {
  .static-slider4 h1 {
    line-height: 36px;
    font-size: 24px;
  }

  .static-slider4 .img-anim {
    position: relative;
    top: 0px;
  }

  .static-slider4 .bg-info-gradiant {
    margin-top: 40px;
  }
}

/*******************
Static slide 5
*******************/
.static-slider5 {
  padding-top: 80px;
}

.static-slider5 .middle-image {
  margin-top: 80px;
  margin-bottom: -100px;
}

.static-slider5 .left-right-bg {
  background-repeat: no-repeat;
  background-position: left center, right center;
}

@media (max-width: 767px) {
  .static-slider5 .left-right-bg {
    background-image: none !important;
  }
}

/*******************
Static slide 6
*******************/
.static-slider6 {
  padding-top: 80px;
}

.static-slider6 .left-right-bg {
  background-repeat: no-repeat;
  background-position: bottom center;
}

.static-slider6 .video-img {
  cursor: pointer;
}

/*******************
Static slide 7
*******************/
.static-slider7 {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  padding: 12% 0;
}

.static-slider7 h1.title {
  font-size: 122px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 130px;
}

@media (max-width: 767px) {
  .static-slider7 h1.title {
    font-size: 60px;
    line-height: 70px;
  }
}

/*******************
Static slide 8
*******************/
.static-slider8 {
  padding: 80px 0 0;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
}

.static-slider8 h1.title {
  font-size: 65px;
  font-weight: 700;
}

.static-slider8 .showcase-img {
  position: relative;
  margin-bottom: -300px;
}

/*******************
Static slide 9
*******************/
.static-slider9 {
  overflow: hidden;
}

.static-slider9 .bg-img {
  background-size: cover;
  background-position: center center;
  position: absolute;
  right: 0px;
  height: 100%;
}

.static-slider9 .bg-img img {
  margin-left: -200px;
  padding-top: 100px;
}

.static-slider9 .container {
  z-index: 1;
}

.static-slider9 .info-detail {
  padding: 15% 15px;
}

.static-slider9 .info-detail .title {
  font-size: 48px;
  font-weight: 700;
  line-height: 50px;
}

.static-slider9 .info-detail .subtitle {
  margin: 50px 0;
}

.static-slider9 .adv-img {
  padding-top: 100px;
}

@media (max-width: 900px) {
  .static-slider9 .bg-img {
    position: relative;
  }

  .static-slider9 .bg-img img {
    margin-left: 0px;
    padding: 20px;
  }

  .static-slider9 .info-detail .title {
    font-size: 40px;
  }
}

/*******************
Static slide 10
*******************/
.static-slider10 {
  padding: 15% 0;
  background-size: cover;
  background-position: center center;
}

.static-slider10 .title {
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 48px;
  line-height: 50px;
}

.static-slider10 .title2 {
  color: #ffffff;
  font-weight: 400;
  font-size: 72px;
  line-height: 72px;
  font-family: "Lavanderia-Sturdy";
}

.static-slider10 h3 {
  color: #ffffff;
  font-weight: normal;
  font-size: 36px;
  line-height: 50px;
  font-family: "Calafia-Regular";
}

.static-slider10 .subtitle {
  color: #ffffff;
}

@media (max-width: 900px) {
  .static-slider10 .title {
    font-size: 40px;
    line-height: 45px;
  }
}

/*******************
Footer 1
*******************/
.footer1 .f1-topbar {
  border-bottom: 1px dashed rgba(120, 130, 140, 0.13);
}

.footer1 .f1-topbar .navbar {
  padding: 0px;
}

.footer1 .f1-topbar .navbar .navbar-nav .nav-item .nav-link {
  color: #8d97ad;
  display: block;
  padding: 15px 13px;
}

.footer1 .f1-topbar .navbar .navbar-nav .nav-item:hover .nav-link,
.footer1 .f1-topbar .navbar .navbar-nav .nav-item.active .nav-link {
  color: #019e7d;
}

.footer1 .f1-middle {
  padding: 40px 0;
}

.footer1 .f1-bottom-bar {
  padding: 20px 0;
  border-top: 1px solid rgba(120, 130, 140, 0.13);
}

/*******************
Footer 2
*******************/
.footer2 {
  padding: 60px 0;
}

.footer2 .f2-topbar {
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}

.footer2 .f2-middle {
  padding-top: 40px;
}

.footer2 .link {
  color: #8d97ad;
  padding: 10px 15px 10px 0;
}

.footer2 .general-listing li a i {
  font-size: 9px;
  margin-right: 10px;
}

.footer2 .info-box .d-flex {
  padding: 15px 0;
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}

.footer2 .subscribe-box {
  background: rgba(120, 130, 140, 0.13);
  padding: 25px;
}

.footer2 .subscribe-box .form-control {
  background: transparent;
  padding: 10px 0;
  border: 0px;
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
  color: #ffffff;
  border-radius: 0px;
}

.footer2 .subscribe-box .form-control:focus {
  border-bottom: 1px solid #ffffff;
}

/*******************
Footer 3
*******************/
.footer3 {
  padding-top: 50px;
}

.footer3 .f3-topbar {
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}

.footer3 .f3-topbar .input-group .form-control {
  font-size: 16px;
}

.footer3 .f3-topbar .input-group .btn {
  padding: 15px 20px;
}

.footer3 .f3-middle {
  padding-top: 40px;
}

.footer3 .general-listing li:hover a,
.footer3 .general-listing li:hover i {
  color: #ffffff;
}

.footer3 .link {
  color: #8d97ad;
}

.footer3 .link:hover {
  color: #ffffff;
}

.footer3 .f3-bottom-bar {
  background: rgba(0, 0, 0, 0.1);
  padding: 20px 0;
}

.footer3 .f3-bottom-bar .link {
  padding: 0 12px;
}

/*******************
Footer 4
*******************/
.footer4 .f4-bottom-bar {
  padding-top: 10px;
  margin-top: 20px;
  border-top: 1px solid rgba(120, 130, 140, 0.13);
}

.footer5 .form-control {
  background: #ffffff;
}

.footer5 .social-links {
  margin: 20px 0;
}

.footer5 .social-links a {
  color: #8d97ad;
  opacity: 0.5;
  padding: 0 15px 0 0;
}

.footer5 .social-links a:hover {
  color: #019e7d;
  opacity: 1;
}

/*******************
Footer 6
*******************/
.footer6 .round-social a {
  background: rgba(120, 130, 140, 0.13);
  color: #8d97ad;
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.footer6 .round-social a:hover {
  color: #ffffff;
}

/*******************
c2a-1
*******************/
.c2a1 {
  background-position: center top;
  background-size: cover;
  background-attachment: fixed;
}

/*******************
c2a-5
*******************/
.c2a5 {
  background-position: center center;
  background-size: cover;
}

/*******************
c2a-8
*******************/
.c2a8 {
  background-position: center center;
  padding: 180px 0;
}

.c2a8 .text-box {
  padding: 30px 20px;
}

/*******************
c2a-9
*******************/
.c2a9 {
  background-position: top center;
  padding: 90px 0px 280px 0px;
}

/*******************
c2a-10
*******************/
.c2a10 {
  background-size: cover;
  background-attachment: fixed;
  background-position: left center;
}

.c2a10 .text-box {
  padding: 30px 20px;
}

/*******************
Blog homepage 1
*******************/
.blog-home1 [class*=col-] {
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.blog-home1 .rowitems [class*=col-]:hover {
  -webkit-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  transform: translateY(-10px);
}

/*******************
Blog homepage 2
*******************/
.blog-home2 [class*=col-] {
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.blog-home2 [class*=col-]:hover {
  -webkit-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  transform: translateY(-10px);
}

.blog-home2 .date-pos {
  padding: 10px;
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 10px;
  color: #ffffff;
  border-radius: 4px;
  text-align: center;
}

.blog-home2 .date-pos span {
  display: block;
  font-size: 30px;
  line-height: 30px;
}

/*******************
Blog homepage 3
*******************/
.blog-home3 .date-pos {
  text-align: center;
  color: #ffffff;
  font-size: 12px;
  padding: 10px;
}

.blog-home3 a {
  overflow: hidden;
}

.blog-home3 a img {
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.blog-home3 a img:hover {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

/*******************
Blog homepage 4
*******************/
.blog-home4 .tweet {
  text-align: center;
}

.blog-home4 .tweet .card-title {
  color: #ffffff;
}

.blog-home4 .tweet .card-title a {
  color: #ffffff;
}

.blog-home4 .tweet .card-title a:hover {
  color: #263238;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .card-columns {
    -webkit-column-count: 2;
    column-count: 2;
  }
}

/*******************
Blog homepage 5
*******************/
.blog-home5 .b-h-box {
  position: relative;
  color: #ffffff;
}

.blog-home5 .b-h-box .card-img-overlay {
  bottom: 0px;
  top: auto;
  -webkit-transition: .2s ease-in;
  -o-transition: .2s ease-in;
  transition: .2s ease-in;
  height: 45%;
  overflow: hidden;
}

.blog-home5 .b-h-box .card-title {
  color: #ffffff;
  margin: 15px 0;
}

.blog-home5 .b-h-box .card-text {
  display: none;
}

.blog-home5 .b-h-box:hover {
  color: #8d97ad;
}

.blog-home5 .b-h-box:hover .card-img-overlay {
  height: 100%;
  background: #ffffff;
  border: 3px solid rgba(120, 130, 140, 0.13);
}

.blog-home5 .b-h-box:hover .card-title {
  color: #263238;
  margin: 15px 0;
}

.blog-home5 .b-h-box:hover .card-text {
  display: block;
}

/*******************
Blog homepage 6
*******************/
.blog-home6 .form-control {
  padding: 13px;
}

.blog-home6 .blog-row {
  margin-bottom: 30px;
}

.blog-home6 .blog-row a {
  color: #263238;
}

.blog-home6 .blog-row a:hover {
  color: #019e7d;
}

.blog-home6 .blog-row h5 {
  font-weight: 500;
  margin-top: 10px;
}

/*******************
contact 2
*******************/
.contact2 {
  padding: 60px 0;
  margin-bottom: 170px;
  background-position: center top;
}

.contact2 .bg-image {
  background-size: cover;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.contact2 .detail-box {
  padding: 40px;
}

.contact2 .detail-box .round-social {
  margin-top: 100px;
}

.contact2 .detail-box .round-social.light a {
  background: transparent;
  color: #ffffff;
  border: 2px solid #ffffff;
  margin: 0 7px;
  height: 49px;
  width: 49px;
  line-height: 49px;
}

.contact2 .contact-container {
  position: relative;
  top: 200px;
}

.contact2 .contact-container .links a {
  color: #8d97ad;
}

/*******************
contact 4
*******************/
.contact4 {
  overflow: hidden;
  position: relative;
}

.contact4 .form-control {
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.5);
  color: #ffffff;
}

.contact4 .form-control:focus {
  border-color: #ffffff;
}

.contact4 input::-webkit-input-placeholder,
.contact4 textarea::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.contact4 input:-ms-input-placeholder,
.contact4 textarea:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.contact4 input::-ms-input-placeholder,
.contact4 textarea::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.contact4 input::placeholder,
.contact4 textarea::placeholder {
  color: rgba(0, 0, 0, 0.7);
}

.contact4 .right-image {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
}

/*******************
Pricing 1
*******************/
.pricing1 .card {
  -webkit-transition: 0.1s;
  -o-transition: 0.1s;
  transition: 0.1s;
}

.pricing1 .card:hover {
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -webkit-font-smoothing: antialiased;
}

.pricing1 .badge {
  top: -13px;
  position: absolute;
  left: 0;
  right: 0;
  width: 100px;
  margin: 0 auto;
}

.pricing1 .title {
  margin-top: 20px;
  font-weight: 500;
  margin-bottom: 5px;
}

.pricing1 .subtitle {
  font-size: 14px;
}

.pricing1 .pricing {
  margin: 20px 0;
}

.pricing1 .pricing sup {
  font-size: 18px;
  top: -20px;
}

.pricing1 .pricing .display-5 {
  color: #263238;
}

.pricing1 .pricing .yearly {
  display: none;
}

.pricing1 .list-inline {
  margin-top: 30px;
}

.pricing1 .list-inline li {
  padding: 8px 0;
  display: block;
}

.pricing1 .bottom-btn {
  position: relative;
  bottom: 0px;
}

/*******************
Pricing 2
*******************/
.pricing2 {
  background-size: cover;
  max-height: 530px;
  margin-bottom: 250px;
}

.pricing2 .pricing-box {
  margin-top: 150px;
}

.pricing2 .above-card {
  margin: -70px -32px -32px;
}

.pricing2 .above-card .card {
  padding: 30px;
}

.pricing2 .pricing-text sup {
  top: -50px;
  font-size: 24px;
}

.pricing2 .pricing-text span {
  font-size: 100px;
  font-weight: 400;
  line-height: 100px;
  color: #263238;
}

.pricing2 .list-inline {
  margin: 0 0 30px 0;
}

.pricing2 .list-inline li {
  padding: 7px 0;
}

@media (max-width: 767px) {
  .pricing2 {
    max-height: 100%;
    margin-bottom: 0px;
  }

  .pricing2 .pricing-box {
    margin-top: 20px;
  }

  .pricing2 .display-5 {
    font-size: 36px;
  }

  .pricing2 .above-card {
    margin: 0;
  }

  .pricing2 .above-card .card {
    padding: 0px;
  }
}

/*******************
Pricing 3
*******************/
.pricing3 .col-lg-2 {
  padding-right: 0px;
  position: relative;
  z-index: 1;
}

.pricing3 .col-lg-10 {
  padding-left: 0px;
}

.pricing3 .nav-pills {
  border: 1px solid rgba(120, 130, 140, 0.13);
  border-radius: 4px;
  border-right: 0px;
}

.pricing3 .nav-pills .nav-link {
  padding: 30px 25px;
}

.pricing3 .nav-pills .nav-link h2 {
  color: #8d97ad;
}

.pricing3 .nav-pills .nav-link.active {
  background: #ffffff;
  border-left: 3px solid #ff4d7e;
  border-radius: 0px;
  margin-left: -3px;
  -webkit-box-shadow: -20px 0 40px rgba(0, 0, 0, 0.1);
  box-shadow: -20px 0 40px rgba(0, 0, 0, 0.1);
}

.pricing3 .nav-pills .nav-link.active h2 {
  color: #263238;
}

.pricing3 .tab-content {
  background: #ffffff;
  overflow: hidden;
  -webkit-box-shadow: 0 0 60px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.1);
}

.pricing3 .tab-content .pricing-box {
  padding: 50px 80px;
}

.pricing3 .tab-content .list-inline {
  margin-top: 20px;
}

.pricing3 .tab-content .list-inline li {
  padding: 30px 0;
  font-weight: 400;
}

.pricing3 .tab-content .list-inline li span {
  display: inline-block;
  vertical-align: middle;
}

.pricing3 .tab-content .list-inline li i {
  font-size: 40px;
  text-align: center;
  vertical-align: middle;
  width: 80px;
  overflow: hidden;
  display: inline-block;
  opacity: 0.5;
}

.pricing3 .tab-content .bg-image {
  background-size: cover;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.pricing3 .tab-content .bg-image .quote-box {
  padding: 40px;
}

@media (max-width: 767px) {
  .pricing3 .col-lg-2 {
    padding-right: 15px;
  }

  .pricing3 .col-lg-10 {
    padding-left: 15px;
  }

  .pricing3 .nav-pills {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    border-bottom: 0px;
    border-right: 1px solid rgba(120, 130, 140, 0.13);
  }

  .pricing3 .nav-pills .nav-link {
    padding: 25px 18px;
  }

  .pricing3 .nav-pills .nav-link.active {
    border-left: 0px;
    border-top: 3px solid #ff4d7e;
    margin-top: -4px;
    margin-left: 0px;
    margin-bottom: -1px;
    -webkit-box-shadow: 0 -20px 40px rgba(0, 0, 0, 0.1);
    box-shadow: 0 -20px 40px rgba(0, 0, 0, 0.1);
  }

  .pricing3 .tab-content .pricing-box {
    padding: 20px;
  }
}

/*******************
Pricing 4
*******************/
.pricing4 .price small {
  color: #8d97ad;
  font-size: 16px;
}

/*******************
Pricing 5
*******************/
.pricing5 .general-listing {
  margin: 90px 0 60px 0;
}

.pricing5 .general-listing.only-li li {
  padding: 20px 0;
  display: block;
}

.pricing5 .card {
  background-size: 100% !important;
}

.pricing5 .yearly {
  display: none;
}

.pricing5 .pricing-box {
  margin-top: 120px;
}

.pricing5 .pricing-box .middle-box {
  margin-top: -75px;
}

.pricing5 .pricing-box .middle-box .general-listing {
  margin-top: 60px;
}

@media (max-width: 767px) {
  .pricing5 .pricing-box {
    margin-top: 40px;
  }

  .pricing5 .pricing-box .middle-box {
    margin-top: 0px;
  }
}

/*******************
Pricing 6
*******************/
.pricing6 .price-box {
  margin: 20px 0;
}

.pricing6 .price-box sup {
  top: -20px;
  font-size: 16px;
}

.pricing6 .price-box .display-5 {
  line-height: 58px;
}

.pricing6 .general-listing {
  padding-left: 30px;
}

.pricing6 .general-listing.only-li li {
  padding: 10px 0;
}

/*******************
Pricing 7
*******************/
.pricing7 .pricing-box sup {
  top: -20px;
  font-size: 16px;
}

.pricing7 .pricing-box .btn {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

/*******************
Pricing 8
*******************/
.pricing8 .pricing-box sup {
  top: -20px;
  font-size: 16px;
}

.pricing8 .pricing-box .btn {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

/*******************
team 1
*******************/
.team1 .pro-pic {
  min-height: 200px;
}

.team1 .pro-pic .card-img-overlay {
  background: rgba(26, 139, 243, 0.87);
  display: none;
}

.team1 .pro-pic .card-img-overlay ul {
  position: relative;
  top: 50%;
  text-align: center;
}

.team1 .pro-pic .card-img-overlay ul li a {
  color: #ffffff;
  padding: 0 10px;
  -webkit-transition: 0.1s ease-in;
  -o-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
  display: block;
}

.team1 .pro-pic .card-img-overlay ul li a:hover {
  -webkit-transform: translate3d(0px, -5px, 0px);
  transform: translate3d(0px, -5px, 0px);
}

.team1 .pro-pic:hover .card-img-overlay {
  display: block;
}

/*******************
team 2
*******************/
.team2 .pro-pic {
  min-height: 200px;
}

.team2 .pro-pic .card-img-overlay {
  background: rgba(26, 139, 243, 0.87);
  display: none;
}

.team2 .pro-pic .card-img-overlay ul {
  position: relative;
  top: 50%;
  text-align: center;
}

.team2 .pro-pic .card-img-overlay ul li a {
  color: #ffffff;
  padding: 0 10px;
  -webkit-transition: 0.1s ease-in;
  -o-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
  display: block;
}

.team2 .pro-pic .card-img-overlay ul li a:hover {
  -webkit-transform: translate3d(0px, -5px, 0px);
  transform: translate3d(0px, -5px, 0px);
}

.team2 .pro-pic:hover .card-img-overlay {
  display: block;
}

.team2 .title {
  margin: 30px 0 0 0;
}

.team2 .subtitle {
  margin: 0 0 20px 0;
  font-size: 13px;
}

/*******************
team 3
*******************/
.team3 ul {
  margin-top: 30px;
}

.team3 ul li a {
  color: #8d97ad;
  padding-right: 15px;
  -webkit-transition: 0.1s ease-in;
  -o-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
  display: block;
}

.team3 ul li a:hover {
  -webkit-transform: translate3d(0px, -5px, 0px);
  transform: translate3d(0px, -5px, 0px);
}

.team3 .title {
  margin: 30px 0 0 0;
}

.team3 .subtitle {
  margin: 0 0 20px 0;
  font-size: 13px;
}

/*******************
team 4
*******************/
.team4 {
  text-align: center;
}

.team4 .pro-pic img {
  border-radius: 100%;
}

.team4 ul {
  margin-top: 30px;
}

.team4 ul li a {
  color: #8d97ad;
  padding-right: 15px;
  -webkit-transition: 0.1s ease-in;
  -o-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
  display: block;
}

.team4 ul li a:hover {
  -webkit-transform: translate3d(0px, -5px, 0px);
  transform: translate3d(0px, -5px, 0px);
}

.team4 .title {
  margin: 30px 0 0 0;
}

.team4 .subtitle {
  margin: 0 0 20px 0;
  font-size: 13px;
}

/*******************
Testimonial 1
*******************/
.testimonial1 .testi1 .card-body {
  padding: 35px;
}

.testimonial1 .testi1 .thumb {
  position: relative;
  padding: 10px 20px 10px;
  padding-left: 90px;
  margin-left: -35px;
  display: inline-block;
  color: #ffffff;
  margin-bottom: 30px;
}

.testimonial1 .testi1 .thumb .thumb-img {
  width: 60px;
  position: absolute;
  left: 20px;
  top: -10px;
}

.testimonial1 .testi1 h5 {
  line-height: 30px;
}

.testimonial1 .testi1 .devider {
  height: 1px;
  background: rgba(120, 130, 140, 0.13);
  margin: 20px 0;
  display: inline-block;
  width: 100px;
}

/*******************
Testimonial 2
*******************/
.testimonial2 .testi2 .image-thumb {
  background: url(../../images/testimonial/greadint-bg.png) no-repeat top center;
  text-align: center;
  padding: 10% 0;
}

.testimonial2 .testi2 .image-thumb img {
  width: 400px;
}

.testimonial2 .testi2 .owl-dots {
  display: inline-block;
  position: relative;
  top: -100px;
}

.testimonial2 .testi2 .owl-dots .owl-dot {
  border-radius: 100%;
  width: 70px;
  height: 70px;
  background-size: cover;
  margin-right: 10px;
  opacity: 0.4;
  cursor: pointer;
}

.testimonial2 .testi2 .owl-dots .owl-dot span {
  display: none;
}

.testimonial2 .testi2 .owl-dots .owl-dot.active,
.testimonial2 .testi2 .owl-dots .owl-dot:hover {
  opacity: 1;
}

@media (max-width: 767px) {
  .testimonial2 .testi2 .owl-dots {
    top: 0px;
  }
}

/*******************
Testimonial 3
*******************/
.testimonial3 .testi3 .card-body {
  padding: 40px;
}

.testimonial3 .testi3 h6 {
  line-height: 26px;
}

.testimonial3 .testi3 .thumb-img img {
  width: 60px;
}

.testimonial3 .testi3 .customer {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}

/*******************
Testimonial 4
*******************/
.testimonial4 .owl-dots {
  counter-reset: dots;
  text-align: left;
}

.testimonial4 .owl-dots .owl-dot {
  padding: 15px 20px 10px 0;
  cursor: pointer;
}

.testimonial4 .owl-dots .owl-dot span {
  display: none;
}

.testimonial4 .owl-dots .owl-dot:before {
  counter-increment: dots;
  content: counter(dots);
}

.testimonial4 .owl-dots .owl-dot.active:before {
  font-weight: 500;
  color: #263238;
  border-bottom: 2px solid #263238;
}

/*******************
Testimonial 5
*******************/
.testimonial5 .testi5 {
  position: relative;
}

.testimonial5 .testi5 .content {
  font-size: 21px;
  line-height: 30px;
  padding: 50px 30px;
}

.testimonial5 .testi5 .owl-item.center .content {
  color: #ffffff;
  background: #ff4d7e;
  background: -webkit-linear-gradient(legacy-direction(to right), #ff4d7e 0%, #ff6a5b 100%);
  background: -webkit-gradient(linear, left top, right top, from(#ff4d7e), to(#ff6a5b));
  background: -webkit-linear-gradient(left, #ff4d7e 0%, #ff6a5b 100%);
  background: -o-linear-gradient(left, #ff4d7e 0%, #ff6a5b 100%);
  background: linear-gradient(to right, #ff4d7e 0%, #ff6a5b 100%);
}

.testimonial5 .testi5 .customer-thumb {
  margin-top: -30px;
  display: none;
}

.testimonial5 .testi5 .customer-thumb img {
  width: 60px;
  margin-bottom: 20px;
}

.testimonial5 .testi5 .owl-item.center .customer-thumb {
  display: block;
}

/*******************
Testimonial 6
*******************/
.testimonial6 .testi6 {
  border-right: 1px solid rgba(120, 130, 140, 0.13);
}

.testimonial6 .testi6 .nav-link {
  border-radius: 0px;
  margin: 8px -2px 8px 0;
}

.testimonial6 .testi6 .nav-link img {
  width: 70px;
  opacity: 0.5;
}

.testimonial6 .testi6 .nav-link.active {
  background: transparent;
  color: #8d97ad;
  border-right: 3px solid #2cdd9b;
}

.testimonial6 .testi6 .nav-link.active img {
  opacity: 1;
}

@media (max-width: 767px) {
  .testimonial6 .testi6 {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    border-right: 0px solid rgba(120, 130, 140, 0.13);
    border-bottom: 1px solid rgba(120, 130, 140, 0.13);
    margin-bottom: 40px;
  }

  .testimonial6 .testi6 .nav-link {
    margin: 0px 0px -2px 0;
    padding: 10px;
  }

  .testimonial6 .testi6 .nav-link.active {
    border-right: 0px solid #2cdd9b;
    border-bottom: 3px solid #2cdd9b;
  }

  .testimonial6 .testi6 .nav-link img {
    width: 40px;
  }
}

/*******************
Testimonial 7
*******************/
.testimonial7 {
  background: #188ef4;
  /* Old browsers */
  background: -moz-linear-gradient(left, #188ef4 0%, #188ef4 48%, #f4f8fa 48%, #f4f8fa 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #188ef4 0%, #188ef4 51%, #f4f8fa 48%, #f4f8fa 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(linear, left top, right top, from(#188ef4), color-stop(48%, #188ef4), color-stop(48%, #f4f8fa), to(#f4f8fa));
  background: -webkit-linear-gradient(left, #188ef4 0%, #188ef4 48%, #f4f8fa 48%, #f4f8fa 100%);
  background: -o-linear-gradient(left, #188ef4 0%, #188ef4 48%, #f4f8fa 48%, #f4f8fa 100%);
  background: linear-gradient(to right, #188ef4 0%, #188ef4 48%, #f4f8fa 48%, #f4f8fa 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$info', endColorstr='$light', GradientType=1);
  /* IE6-9 */
}

.testimonial7 .testi7 .title {
  margin-top: 80px;
}

.testimonial7 .testi7 .owl-nav {
  float: right;
}

.testimonial7 .testi7 .owl-nav [class*='owl-'] {
  background: transparent;
  color: #8d97ad;
  font-size: 34px;
  margin: -100px 0 0 0px;
}

.testimonial7 .testi7 .owl-nav [class*='owl-']:hover {
  color: #263238;
}

@media (max-width: 767px) {
  .testimonial7 {
    background: -moz-linear-gradient(top, #188ef4 0%, #188ef4 51%, #f4f8fa 51%, #f4f8fa 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #188ef4 0%, #188ef4 51%, #f4f8fa 51%, #f4f8fa 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: -webkit-gradient(linear, left top, left bottom, from(#188ef4), color-stop(51%, #188ef4), color-stop(51%, #f4f8fa), to(#f4f8fa));
    background: -o-linear-gradient(top, #188ef4 0%, #188ef4 51%, #f4f8fa 51%, #f4f8fa 100%);
    background: linear-gradient(to bottom, #188ef4 0%, #188ef4 51%, #f4f8fa 51%, #f4f8fa 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$info', endColorstr='$light', GradientType=1);
    /* IE6-9 */
  }
}

/*******************
Testimonial 8
*******************/
.testimonial8 .testi8 {
  margin-bottom: 80px;
}

.testimonial8 .testi8 .bg {
  background-size: cover;
  padding: 150px 0;
}

.testimonial8 .testi8 .play-icon {
  font-size: 34px;
  color: #ffffff;
}

.testimonial8 .testi8 .owl-dots {
  display: inline-block;
  position: relative;
  top: -45px;
}

.testimonial8 .testi8 .owl-dots .owl-dot {
  border-radius: 100%;
  width: 70px;
  height: 70px;
  background-size: cover;
  margin-right: 10px;
  opacity: 0.4;
  cursor: pointer;
}

.testimonial8 .testi8 .owl-dots .owl-dot span {
  display: none;
}

.testimonial8 .testi8 .owl-dots .owl-dot.active,
.testimonial8 .testi8 .owl-dots .owl-dot:hover {
  opacity: 1;
}

/*******************
Testimonial 9
*******************/
.testimonial9 .card {
  position: relative;
}

.testimonial9 .card .p-40::after {
  position: absolute;
  bottom: -15px;
  left: 20;
  content: '';
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid #ffffff;
}

.testimonial9 .owl-theme .owl-dots .owl-dot.active span,
.testimonial9 .owl-theme .owl-dots .owl-dot:hover span {
  background: #188ef4;
}

.testimonial9 .owl-dots {
  position: absolute;
  left: -98%;
  top: 70%;
}

.testimonial9 .devider {
  height: 2px;
  width: 40px;
  display: inline-block;
  margin: 22px 0;
}

.testimonial9 h5.text {
  line-height: 30px;
  font-weight: 300;
}

.testimonial9 .customer-thumb img {
  width: 60px;
  margin-right: 20px;
}

.testimonial9 .d-flex {
  margin-left: 25px;
}

@media (max-width: 767px) {
  .testimonial9 .owl-dots {
    position: relative;
    top: 0px;
    left: 0px;
  }
}

/*******************
Testimonial 10
*******************/
.testimonial10 .testi10 .quote-bg {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  padding: 60px;
  background: url(../../images/up-quote.png) no-repeat top left, url(../../images/down-quote.png) no-repeat bottom right;
}

.testimonial10 .testi10 h3 {
  line-height: 40px;
}

.testimonial10 .testi10 .customer-thumb {
  margin: 15px 0;
}

.testimonial10 .testi10 .customer-thumb img {
  width: 60px;
}

@media (max-width: 767px) {
  .testimonial10 .testi10 h3 {
    line-height: 30px;
    font-size: 20px;
  }
}

/*-------------------*/
/*Banner1 Style Start*/
/*-------------------*/
#banner1 {
  padding-top: 200px;
  padding-bottom: 200px;
  background-size: cover;
}

#banner1 h2 {
  font-size: 48px;
  line-height: 55px;
  letter-spacing: -1px;
}

#banner1 input {
  line-height: 62px;
  padding: 0;
  margin: 0;
  border: none;
  display: inline-block;
  vertical-align: top;
}

#banner1 input[type="text"] {
  color: #8d97ad;
  border-radius: 36px 0 0 36px;
  text-indent: 35px;
  padding-right: 15px;
  width: 61%;
  font-weight: 300;
  -webkit-box-shadow: -5px 0 30px 0 rgba(0, 0, 0, 0.05);
  box-shadow: -5px 0 30px 0 rgba(0, 0, 0, 0.05);
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

#banner1 input[type="submit"] {
  border-radius: 0 36px 36px 0;
  width: auto;
  padding: 0 30px;
  cursor: pointer;
  margin-left: -4px;
  -webkit-box-shadow: 5px 0 30px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 5px 0 30px 0 rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

#banner1 input[type="submit"]:hover {
  opacity: 0.7;
}

@media (max-width: 1100px) {
  #banner1 h2 {
    font-size: 40px;
    line-height: 55px;
  }

  #banner1 input[type="text"] {
    width: 59%;
  }

  #banner1 input[type="submit"] {
    padding: 0 20px;
  }
}

@media (max-width: 992px) {
  .banner {
    height: auto !important;
    padding: 130px 0;
  }

  #banner1 {
    background-position: right -6em center;
    background-size: cover;
    padding: 130px 0 200px 0;
  }

  #banner1 h2 {
    font-size: 30px;
    line-height: 35px;
  }
}

@media (max-width: 767px) {
  .banner {
    padding: 30px 0;
  }

  #banner1 {
    background-size: cover;
    background-position: right center;
    padding: 120px 0 60px;
  }

  #banner1 input[type="submit"] {
    padding: 0 30px;
  }

  #banner1 img {
    margin-top: 25px;
  }
}

@media (max-width: 428px) {
  #banner1 input {
    font-size: 14px;
    line-height: 50px;
  }

  #banner1 input[type="text"] {
    border-radius: 25px 0 0 25px;
    text-indent: 20px;
  }

  #banner1 input[type="submit"] {
    border-radius: 0 25px 25px 0;
    padding: 0 15px;
  }
}

@media (max-width: 320px) {
  #banner1 input[type="submit"] {
    padding: 0 10px;
  }
}

/*-------------------*/
/*Banner2 Style Start*/
/*-------------------*/
#banner2 {
  max-height: 800px;
}

#banner2 .w-50 {
  width: 50%;
}

#banner2 .form-row {
  margin: 0;
}

#banner2 label.font-12 {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 5px;
}

#banner2 input[type=text] {
  color: #8d97ad;
  font-weight: 300;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

#banner2 button {
  cursor: pointer;
  border-radius: 0;
}

#banner2 .date label {
  cursor: pointer;
  margin: 0;
}

@media (max-width: 370px) {

  #banner2 .left,
  #banner2 .right {
    padding: 25px;
  }
}

@media (max-width: 320px) {

  #banner2 .left,
  #banner2 .right {
    padding: 25px 15px;
  }
}

/*-------------------*/
/*Banner3 Style Start*/
/*-------------------*/
#banner3 h2 {
  font-size: 36px;
  line-height: 1.2;
}

#banner3 input,
#banner3 button {
  line-height: 30px;
}

#banner3 input[type=text] {
  border-radius: 3px 0 0 3px;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

#banner3 button {
  border-radius: 0 3px 3px 0;
  cursor: pointer;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  border: none;
}

#banner3 button:hover {
  opacity: 0.8;
}

#banner3 .suggetion i {
  font-size: 34px;
  color: #ffffff;
  position: absolute;
  left: 0;
  top: 0;
}

#banner3 .suggetion span {
  display: inline-block;
  padding-left: 50px;
}

@media (max-width: 992px) {
  #banner3 h2 {
    font-size: 30px;
    padding: 0 15px;
  }
}

@media (max-width: 767px) {
  #banner3 h2 {
    font-size: 26px;
  }

  #banner3 .col-sm-6 {
    margin-right: auto;
    margin-left: 0 !important;
  }
}

@media (max-width: 428px) {
  #banner3 {
    padding-bottom: 50px;
  }

  #banner3 h2 {
    font-size: 22px;
  }
}

@media (max-width: 590px) {
  #banner3 .form-data {
    padding: 0 15px;
  }

  #banner3 .suggetion {
    padding: 0 15px;
  }
}

/*-------------------*/
/*Banner4 Style Start*/
/*-------------------*/
#banner4 h2 {
  font-size: 36px;
  line-height: 1.2;
}

#banner4 button {
  border-radius: 0;
  line-height: 43px;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  font-size: 20px;
}

#banner4 button:hover {
  opacity: 0.95;
}

#banner4 input,
#banner4 .span-select {
  border-radius: 0;
  font-size: 12px;
  line-height: 1.7;
  border: none;
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
  color: #8d97ad;
  font-weight: 300;
  display: block;
}

#banner4 .form-data {
  border: 15px solid rgba(34, 50, 70, 0.5);
}

#banner4 .form-data select {
  background: tranparent;
  font-size: 12px;
  color: #8d97ad;
  font-weight: 300;
}

#banner4 .form-data .sec-icon i {
  left: 15px;
  top: 18px;
  position: absolute;
  font-size: 20px;
  z-index: 10;
}

#banner4 .form-data .date label {
  position: absolute;
  right: 15px;
  top: 8px;
}

#banner4 .form-data .date label i {
  left: 0;
  top: 0;
}

#banner4 .form-data .span-select {
  overflow: hidden;
  position: relative;
}

#banner4 .form-data .span-select i {
  right: 0;
  top: 8px;
  left: auto;
  position: absolute;
  font-size: 20px;
}

#banner4 .form-data .span-select select {
  min-width: 250px;
}

@media (max-width: 767px) {
  #banner4 .b-b {
    border: none;
  }

  #banner4 h2 {
    font-size: 32px;
  }

  #banner4 input {
    margin-left: 20px;
  }
}

@media (max-width: 428px) {
  #banner4 .form-data {
    overflow: hidden;
  }

  #banner4 .form-data .span-select select {
    width: 110%;
  }
}

@media (max-width: 320px) {

  #banner4 input,
  #banner4 select {
    padding-left: 0;
    padding-right: 0;
  }

  #banner4 input {
    margin-left: 45px;
  }

  #banner4 .form-data .bg-white {
    padding-left: 0;
    padding-right: 0;
  }

  #banner4 .form-data .sec-icon i {
    left: 10px;
  }

  #banner4 .form-data .date label {
    right: 25px;
  }

  #banner4 .form-data .span-select i {
    right: 10px;
    left: auto;
  }

  #banner4 .form-data .span-select select {
    margin-left: 20px;
    width: 100%;
  }
}

/*******************
Form-banner 5
*******************/
.form5 {
  background-size: cover;
  background-attachment: fixed;
  background-position: left center;
}

.form5 .text-box {
  padding: 10px 15px;
}

.form5 .input-icon i {
  top: 17px;
  right: 30px;
}

/*******************
form 6
*******************/
.form6 {
  overflow: hidden;
}

.form6 .bg-img {
  background-size: cover;
  background-position: center center;
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
}

.form6 .container {
  z-index: 1;
}

/*******************
form 8
*******************/
.form8 .blog-row {
  margin-bottom: 30px;
}

.form8 .blog-row a {
  color: #263238;
}

.form8 .blog-row a:hover {
  color: #019e7d;
}

.form8 .blog-row h5 {
  font-weight: 500;
  margin-top: 10px;
}

.form10 {
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 100%;
}

@media (max-width: 1023px) {
  .form10 h1 {
    margin-top: 25px;
  }

  .form10 .have-ac {
    margin-top: 25px;
  }
}

/*******************
slide 1
*******************/
.slider1 {
  position: relative;
}

.slider1 h2 {
  display: inline-block;
  color: #ffffff;
  font-weight: 800;
  font-size: 36px;
  padding: 20px;
  text-transform: uppercase;
  margin: 0;
}

.slider1 p {
  font-size: 18px;
}

.slider1 p a {
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #8d97ad;
  padding-left: 20px;
  padding-right: 60px;
}

.slider1 p a i {
  opacity: 1;
  right: -2rem;
  color: #2cdd9b;
}

.slider1 p a:hover {
  color: #263238;
}

.slider1 p a:hover span {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slider1 p a:hover i {
  right: -2.7rem;
  color: #2cdd9b;
}

.slider1 .slide-image {
  min-width: 716px;
  min-height: 375px;
}

@media (max-width: 767px) {
  .slider1 .slide-image {
    min-height: 300px;
  }

  .slider1 h2 {
    font-size: 20px;
  }

  .slider1 p a {
    font-size: 14px;
  }
}

/*******************
slide 2
*******************/
.slider2 .bs-slider,
.slider2 .carousel-item {
  max-height: 900px;
}

.slider2 .bs-slider-overlay {
  background: rgba(32, 35, 43, 0.8);
}

.slider2 .slider-control span {
  width: 53px;
  height: 53px;
  line-height: 53px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #fff;
}

.slider2 label {
  line-height: 27px;
  border-radius: 0;
  color: #1a1a1a;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.slider2 h2 {
  line-height: 42px;
  font-size: 36px;
}

.slider2 p {
  line-height: 24px;
  font-weight: 400;
}

.slider2 i.icon-Play-Music {
  font-size: 32px;
}

@media (max-width: 1280px) {

  .slider2,
  .slider3 {
    overflow-y: hidden;
  }

  .slider2 .slide-image,
  .slider3 .slide-image {
    height: 550px;
    width: auto !important;
  }

  .slider2 .slider-control span,
  .slider3 .slider-control span {
    width: 44px;
    height: 44px;
    line-height: 44px;
  }
}

@media (max-width: 1100px) {

  .slider2 h2,
  .slider3 h2 {
    font-size: 34px;
  }

  .slider2 .slide-image,
  .slider3 .slide-image {
    left: -20%;
    position: relative;
  }

  .slider2 .slide-text,
  .slider3 .slide-text {
    width: 80% !important;
  }
}

@media (max-width: 992px) {
  .slider2 video {
    width: 120%;
  }
}

@media (max-width: 767px) {

  .slider2 .slider-control span,
  .slider3 .slider-control span {
    width: 34px;
    height: 34px;
    line-height: 34px;
  }

  .slider2 .slide-text,
  .slider3 .slide-text {
    padding: 0 !important;
    width: 80% !important;
  }

  .slider2 h2,
  .slider3 h2 {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 30px;
  }

  .slider2 .slide-image,
  .slider3 .slide-image {
    left: 0;
    height: 300px;
  }

  .slider2 .btn,
  .slider3 .btn {
    padding-left: 15px;
    padding-right: 15px;
  }

  .slider2 .btn.btn-md,
  .slider3 .btn.btn-md {
    padding-left: 25px;
    padding-right: 25px;
  }

  .slider2 .btn-pad,
  .slider3 .btn-pad {
    padding-right: 0;
  }
}

@media (max-width: 428px) {

  .slider2 .slider-control span,
  .slider3 .slider-control span {
    width: 26px;
    height: 26px;
    line-height: 26px;
    font-size: 12px !important;
  }

  .slider2 h2,
  .slider3 h2 {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  .slider2 i.icon-Play-Music,
  .slider3 i.icon-Play-Music {
    font-size: 26px;
  }

  .slider2 a.btn-md,
  .slider3 a.btn-md {
    text-align: center;
    margin-bottom: 10px;
  }

  .slider2 .slide-image,
  .slider3 .slide-image {
    left: -75%;
    height: 380px;
  }

  .slider2 .btn,
  .slider3 .btn {
    padding-left: 0;
  }

  .slider2 video,
  .slider3 video {
    width: 160%;
  }
}

/*******************
slide 3
*******************/
.slider3 .video-img {
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
}

.slider3 .video-img {
  cursor: pointer;
}

.slider3 .bs-slider-overlay {
  background: rgba(32, 35, 43, 0.8);
}

.slider3 .slider-control span {
  width: 53px;
  height: 53px;
  line-height: 53px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #ffffff;
}

.slider3 label {
  line-height: 27px;
  border-radius: 0;
  color: #263238;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.slider3 h2 {
  line-height: 42px;
  font-size: 36px;
}

.slider3 p {
  line-height: 24px;
  font-weight: 400;
}

.slider3 i.icon-Play-Music {
  font-size: 32px;
}

@media (max-width: 1280px) {
  .slider3 {
    overflow-y: hidden;
  }

  .slider3 .slide-image {
    height: 550px;
    width: auto !important;
  }

  .slider3 .slider-control span {
    width: 44px;
    height: 44px;
    line-height: 44px;
  }
}

@media (max-width: 1100px) {
  .slider3 h2 {
    font-size: 34px;
  }

  .slider3 .slide-image {
    left: -20%;
    position: relative;
  }

  .slider3 .slide-text {
    width: 80% !important;
  }
}

@media (max-width: 767px) {
  .slider3 .slider-control span {
    width: 34px;
    height: 34px;
    line-height: 34px;
  }

  .slider3 .slide-text {
    padding: 0 !important;
    width: 80% !important;
  }

  .slider3 h2 {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 30px;
  }

  .slider3 .slide-image {
    left: 0;
    height: 300px;
  }

  .slider3 .btn {
    padding-left: 15px;
    padding-right: 15px;
  }

  .slider3 .btn.btn-md {
    padding-left: 25px;
    padding-right: 25px;
  }

  .slider3 .btn-pad {
    padding-right: 0;
  }
}

@media (max-width: 428px) {
  .slider3 .slider-control span {
    width: 26px;
    height: 26px;
    line-height: 26px;
    font-size: 12px !important;
  }

  .slider3 h2 {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  .slider3 i.icon-Play-Music {
    font-size: 26px;
  }

  .slider3 a.btn-md {
    text-align: center;
    margin-bottom: 10px;
  }

  .slider3 .slide-image {
    left: -75%;
    height: 380px;
  }

  .slider3 .btn {
    padding-left: 0;
  }

  .slider3 video {
    width: 160%;
  }
}

/*******************
slide 4
*******************/
@-webkit-keyframes imagescale {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
  }

  100% {
    transform: scale(1.3);
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -o-transform: scale(1.3);
  }
}

@keyframes imagescale {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
  }

  100% {
    transform: scale(1.3);
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -o-transform: scale(1.3);
  }
}

.slider4 .slide-image {
  animation: imagescale 15s ease-in-out infinite alternate;
  -webkit-animation: imagescale 15s ease-in-out infinite alternate;
  -moz-animation: imagescale 15s ease-in-out infinite alternate;
  -o-webkit-animation: imagescale 15s ease-in-out infinite alternate;
}

.slider4 .carousel-indicators {
  bottom: 0;
}

.slider4 .carousel-indicators li {
  background-color: #ffffff;
}

.slider4 .carousel-indicators li.active {
  background-color: #019e7d;
}

.slider4 label {
  font-style: italic;
  font-family: 'PlayFairDisplay', sans-sarif;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.slider4 ul {
  list-style: none;
  margin: 0;
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

.slider4 ul li {
  line-height: 36px;
}

.slider4 h2 {
  font-size: 36px;
  line-height: 42px;
  font-size: 36px;
}

.slider4 p {
  font-size: 18px;
}

.slider4 .slider-control span {
  width: 53px;
  height: 53px;
  line-height: 53px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #263238;
  color: #263238;
}

.slider4 .btn-secondary {
  background: transparent;
  color: #263238;
}

.slider4 .btn-secondary:hover {
  color: #263238 !important;
}

@media (max-width: 768px) {
  .slider4 h2 {
    font-size: 28px;
    line-height: 36px;
  }

  .slider4 .slide-text {
    padding: 0 !important;
    width: 95% !important;
  }

  .slider4 .slide-image {
    height: 480px;
    width: auto !important;
    position: relative;
  }

  .slider4 .btn-md {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (max-width: 428px) {
  .slider4 h2 {
    font-size: 23px;
    line-height: 30px;
  }

  .slider4 .slide-image {
    left: -90%;
    height: 380px;
  }

  .slider4 .btn-md {
    text-align: center;
    margin-bottom: 10px;
  }

  .slider4 .btn-secondary {
    margin-left: 0 !important;
  }
}

/*******************
slide 5
*******************/
@-webkit-keyframes imageScaleOut {
  0% {
    transform: scale(1.3);
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -o-transform: scale(1.3);
  }

  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
  }
}

@keyframes imageScaleOut {
  0% {
    transform: scale(1.3);
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -o-transform: scale(1.3);
  }

  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.slider5 .bs-slider-overlay {
  display: none;
  background-color: rgba(247, 247, 247, 0.7);
}

.slider5 .slide-image {
  animation: imageScaleOut 15s ease-in-out infinite alternate, fadeIn 0.5s ease-in-out alternate;
  -webkit-animation: imageScaleOut 15s ease-in-out infinite alternate, fadeIn 0.5s ease-in-out alternate;
  -moz-animation: imageScaleOut 15s ease-in-out infinite alternate, fadeIn 0.5s ease-in-out alternate;
  -o-webkit-animation: imageScaleOut 15s ease-in-out infinite alternate, fadeIn 0.5s ease-in-out alternate;
}

.slider5 h2 {
  font-size: 46px;
  line-height: 46px;
}

.slider5 h3 {
  font-size: 21px;
}

.slider5 .carousel-indicators {
  bottom: 0;
}

.slider5 .carousel-indicators li {
  background-color: rgba(0, 0, 0, 0.15);
}

.slider5 .carousel-indicators li.active {
  background-color: #019e7d;
}

.slider5 .promo-code {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
  margin-top: 40px;
  background-size: cover;
  padding: 30px 50px;
  -webkit-box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
}

@media (max-width: 1280px) {
  .slider5 {
    overflow-y: hidden;
  }

  .slider5 .slide-image {
    height: 550px;
    width: auto !important;
  }
}

@media (max-width: 1100px) {
  .slider5 {
    text-shadow: 1px 1px 0 #ffffff;
  }

  .slider5 .slide-image {
    position: relative;
  }

  .slider5 h2 {
    font-size: 40px;
  }

  .slider5 .promo-code {
    padding: 30px;
  }
}

@media (max-width: 991px) {
  .slider5 h2 {
    font-size: 44px;
  }

  .slider5 .promo-code {
    padding: 30px 50px;
  }

  .slider5 .slide-image {
    left: -30%;
  }
}

@media (max-width: 767px) {
  .slider5 .slide-image {
    left: -10%;
  }

  .slider5 .slide-text {
    padding: 0 !important;
    width: 95% !important;
  }

  .slider5 .slide-image {
    height: 380px;
  }

  .slider5 .bs-slider-overlay {
    display: block;
  }

  .slider5 p {
    color: #8d97ad;
  }

  .slider5 .promo-code {
    padding: 10px 50px;
  }
}

@media (max-width: 428px) {
  .slider5 h2 {
    font-size: 33px;
    line-height: 34px;
  }

  .slider5 .slide-image {
    left: -50%;
  }

  .slider5 .promo-code {
    padding: 10px 20px;
  }
}

@media (max-width: 320px) {
  .slider5 h2 {
    font-size: 28px;
    line-height: 30px;
  }

  .slider5 h3 {
    font-size: 18px;
  }
}

/*******************
slide 6
*******************/
.slider6 .slide-text {
  bottom: 0;
  top: auto !important;
  -ms-transform: translate(-50%, 0) !important;
  transform: translate(-50%, 0) !important;
  -webkit-transform: translate(-50%, 0) !important;
  -moz-transform: translate(-50%, 0) !important;
}

.slider6 .slider-control span {
  width: 53px;
  height: 53px;
  line-height: 53px;
  border-radius: 50%;
}

.slider6 h2 {
  line-height: 42px;
  font-size: 36px;
}

.slider6 .btn-md {
  border: 1px solid #ffffff;
}

.slider6 .slide-content {
  padding: 40px;
  position: relative;
  opacity: 0.9;
}

.slider6 .price-tag {
  position: absolute;
  right: -65px;
  top: -65px;
  width: 130px;
  height: 130px;
  padding-top: 37px;
  border-radius: 50%;
}

.slider6 .price-tag span {
  font-weight: 300;
  font-size: 36px;
  line-height: 36px;
}

@media (max-width: 767px) {
  .slider6 .slider-control span {
    width: 34px;
    height: 34px;
    line-height: 34px;
  }

  .slider6 h2 {
    font-size: 28px;
    line-height: 36px;
  }

  .slider6 .slide-text {
    padding: 0 !important;
    width: 80% !important;
  }

  .slider6 .slide-image {
    height: 430px;
  }

  .slider6 .slide-content {
    padding: 30px;
  }
}

@media (max-width: 590px) {
  .slider6 .price-tag {
    right: 0;
    top: -80px;
    left: 0;
    margin: auto;
    padding-top: 20px;
    width: 100px;
    height: 100px;
  }

  .slider6 .price-tag span {
    font-size: 30px;
  }

  .slider6 .price-tag label {
    font-size: 12px;
  }
}

@media (max-width: 428px) {
  .slider6 .slider-control span {
    width: 26px;
    height: 26px;
    line-height: 26px;
    font-size: 12px !important;
  }

  .slider6 .slide-content {
    padding: 30px 20px;
  }

  .slider6 .slide-image {
    left: -90%;
  }

  .slider6 h2 {
    font-size: 22px;
    line-height: 30px;
  }

  .slider6 .btn-md {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (max-width: 320px) {
  .slider6 .slide-content {
    padding: 30px 15px;
  }

  .slider6 h2 {
    font-size: 20px;
  }

  .slider6 .btn-md {
    padding-left: 20px;
    padding-right: 20px;
  }

  .slider6 .slide-image {
    height: 390px;
  }
}

/*******************
slide 7
*******************/
/*-------------------*/
/*Slider7 Style Start*/
/*-------------------*/
@keyframes imagescale {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
  }

  100% {
    transform: scale(1.3);
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -o-transform: scale(1.3);
  }
}

.slider7 .slide-image {
  animation: imagescale 15s ease-in-out infinite alternate;
  -webkit-animation: imagescale 15s ease-in-out infinite alternate;
  -moz-animation: imagescale 15s ease-in-out infinite alternate;
  -o-webkit-animation: imagescale 15s ease-in-out infinite alternate;
}

.slider7 h2 {
  font-size: 32px;
}

.slider7 p {
  line-height: 26px;
}

.slider7 .slide-content {
  padding: 80px 25px;
  position: relative;
  opacity: 0.9;
  border-radius: 50%;
  max-width: 397px;
  min-height: 397px;
}

.slider7 label {
  line-height: 31px;
  min-width: 110px;
}

.slider7 a {
  border-radius: 50%;
  height: 60px;
  width: 60px;
  position: absolute;
  font-size: 28px;
  right: 70px;
  bottom: 0;
}

.slider7 a i {
  line-height: 60px;
}

.slider7 .carousel-indicators {
  bottom: 0;
}

.slider7 .carousel-indicators li {
  background-color: rgba(0, 0, 0, 0.3);
}

.slider7 .carousel-indicators li.active {
  background-color: #019e7d;
}

@media (max-width: 768px) {
  .slider7 .slide-text {
    padding: 0 !important;
    width: 100% !important;
  }

  .slider7 .slide-image {
    height: 430px;
    left: -30%;
    position: relative;
    width: auto !important;
  }
}

@media (max-width: 428px) {
  .slider7 .slide-image {
    left: -90%;
    width: auto;
  }

  .slider7 .slide-content {
    min-width: 100px;
    max-width: 300px;
    min-height: 300px;
    padding: 40px 25px;
  }

  .slider7 h2 {
    font-size: 28px;
  }

  .slider7 p {
    font-size: 14px;
    margin-top: 20px !important;
  }

  .slider7 a {
    height: 50px;
    width: 50px;
    font-size: 26px;
    right: 55px;
  }

  .slider7 a i {
    line-height: 50px;
  }
}

/*******************
slide 1
*******************/
.slider8 .slide-content {
  padding: 40px 30px;
  position: relative;
}

.slider8 .slide1 h2 {
  font-size: 18px;
  line-height: 28px;
}

.slider8 .slide1 label,
.slider8 .slide1 b {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
  line-height: 26px;
}

.slider8 .slide1 .b-r {
  border-bottom: none;
}

.slider8 .slide1 .col-6 {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.slider8 h3 {
  font-size: 26px;
}

@media (max-width: 1280px) {
  .slider8 {
    overflow-y: hidden;
  }

  .slider8 .slide-image {
    height: 550px;
    width: auto !important;
  }
}

@media (max-width: 1100px) {
  .slider8 .slide-image {
    left: -20%;
    position: relative;
  }

  .slider8 .slide-text {
    width: 80% !important;
  }

  .slider8 .slide-content {
    min-width: 350px;
    right: 12%;
  }

  .slider8 h2.text-white {
    font-size: 28px;
  }
}

@media (max-width: 992px) {
  .slider8 .slide-content {
    min-width: 50px;
    right: auto;
  }
}

@media (max-width: 767px) {
  .slider8 .slide-text {
    padding: 0 !important;
    width: 100% !important;
  }

  .slider8 .slide-content {
    opacity: 0.9;
  }

  .slider8 .slide-image {
    height: 450px;
    left: -30%;
  }

  .slider8 .carousel-indicators {
    bottom: -10px;
  }
}

@media (max-width: 428px) {
  .slider8 .slide-image {
    left: -100%;
  }

  .slider8 h3 {
    font-size: 24px;
  }

  .slider8 .slide-content {
    padding: 30px;
  }
}

/*******************
slide 9
*******************/
.slider9 p {
  color: #b8d0ff;
}

.slider9 p a {
  text-decoration: underline;
}

.slider9 p a:hover {
  text-decoration: none;
}

.slider9 .carousel-indicators {
  right: auto;
  margin-left: 11%;
  margin-right: 11%;
}

.slider9 .btn {
  border: 1px solid #fff;
}

.slider9 .col-lg-9 {
  max-width: 400px;
}

@media (max-width: 1100px) {
  .slider9 .col-md-11 {
    min-width: 355px;
  }

  .slider9 p {
    font-size: 14px;
  }

  .slider9 .carousel-indicators {
    right: 0;
  }
}

@media (max-width: 992px) {
  .slider9 .col-md-11 {
    min-width: 250px;
  }

  .slider9 h2 {
    font-size: 22px;
    line-height: 30px;
  }
}

@media (max-width: 767px) {

  .slider9 .bs-slider,
  .slider9 .carousel-item {
    max-height: 100% !important;
  }

  .slider9 .slide-text {
    position: relative;
  }

  .slider9 .col-md-11 {
    padding: 25px 15px 50px;
  }

  .slider9 .col-lg-9 {
    max-width: 100%;
  }

  .slider9 .carousel-indicators {
    bottom: -5px;
  }
}

@media (max-width: 320px) {
  .slider9 h2 {
    font-size: 17px;
    line-height: 26px;
  }
}

@media (min-width: 1900px) and (max-width: 2700px) {
  .slider9 .carousel-indicators {
    margin-left: 20%;
  }
}

@media (min-width: 1970px) and (max-width: 2700px) {
  .slider9 .slide-text {
    -ms-transform: translate(-50%, -100%) !important;
    transform: translate(-50%, -100%) !important;
    -webkit-transform: translate(-50%, -100%) !important;
    -moz-transform: translate(-50%, -100%) !important;
  }

  .slider9 .carousel-indicators {
    margin-left: 26%;
  }
}

/*******************
slide 10
*******************/
@keyframes imagescale {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
  }

  100% {
    transform: scale(1.3);
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -o-transform: scale(1.3);
  }
}

.slider10 .slide-image {
  animation: imagescale 15s ease-in-out infinite alternate;
  -webkit-animation: imagescale 15s ease-in-out infinite alternate;
  -moz-animation: imagescale 15s ease-in-out infinite alternate;
  -o-webkit-animation: imagescale 15s ease-in-out infinite alternate;
}

.slider10 h2 {
  font-size: 32px;
}

.slider10 .slide-content {
  padding: 40px;
  position: relative;
  max-width: 360px;
}

.slider10 label {
  line-height: 20px;
  position: absolute;
  top: -12px;
}

.slider10 .specifiaction-box {
  padding: 20px 40px;
}

.slider10 .specifiaction {
  font-size: 13px;
}

.slider10 .tag {
  color: #ffffff;
  font-size: 13px;
  padding: 5px 7px;
  vertical-align: middle;
  position: relative;
  font-weight: 300;
  top: -2px;
}

.slider10 .tag::after {
  border-bottom: 13px transparent solid;
  border-left: 7px #ff4d7e solid;
  border-top: 12px transparent solid;
  content: '';
  height: 0;
  right: -7px;
  position: absolute;
  top: 0;
  width: 0;
}

.slider10 .carousel-indicators {
  bottom: 0;
}

.slider10 .carousel-indicators li {
  background-color: rgba(0, 0, 0, 0.3);
}

.slider10 .carousel-indicators li.active {
  background-color: #263238;
}

@media (max-width: 767px) {

  /*This is for the slider*/
  .slider10 .slide-image {
    height: 500px;
  }

  .slider10 h2 {
    font-size: 28px;
  }

  .slider10 p {
    font-size: 14px;
    margin-top: 20px !important;
  }
}

/*******************
Portfolio 1
*******************/
.portfolio1 .filterby a {
  padding: 10px;
  font-weight: 400;
  color: #8d97ad;
}

.portfolio1 .filterby a:hover {
  color: #019e7d;
}

.portfolio1 .portfolio-box {
  margin-top: 60px;
}

.portfolio1 .portfolio-box .filter .overlay-box {
  margin-bottom: 30px;
  position: relative;
}

.portfolio1 .portfolio-box .filter .overlay-box .overlay {
  position: absolute;
  background: rgba(29, 200, 205, 0.9);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  height: 0px;
  width: 100%;
  display: none;
  padding-top: 30%;
}

.portfolio1 .portfolio-box .filter .overlay-box .overlay .port-text {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  position: absolute;
  top: 0px;
  width: 0px;
  text-align: center;
}

.portfolio1 .portfolio-box .filter .overlay-box .overlay .port-text h5 {
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0px;
}

.portfolio1 .portfolio-box .filter .overlay-box .overlay .port-text span {
  color: #ffffff;
  font-size: 14px;
}

.portfolio1 .portfolio-box .filter .overlay-box:hover .overlay {
  display: block;
  top: 0px;
  height: 100%;
}

.portfolio1 .portfolio-box .filter .overlay-box:hover .overlay .port-text {
  top: 43%;
  width: 100%;
}

/*******************
Client 1
*******************/
.client1 .client-box {
  margin: 0px;
  margin-top: 40px;
}

.client1 .client-box .box {
  min-height: 130px;
  border: 1px solid rgba(120, 130, 140, 0.13);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/*
Template Name: Wrapkit Ui Kit
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
/*============================================================== 
 For Laptop & above all (1650px) 
 ============================================================== */
/*============================================================== 
 For Laptop & above all (1370px) 
 ============================================================== */
/*-- ============================================================== 
 Small Desktop & above all (1024px) 
 ============================================================== */
@media (min-width: 1024px) {
  .contact4 .contact-box {
    padding: 80px 105px 80px 0px;
  }

  .hover-dropdown .navbar-nav>.dropdown:hover>.dropdown-menu {
    display: block;
    margin-top: 0px;
  }

  .navbar-nav>.dropdown .dropdown-menu {
    min-width: 210px;
    margin-top: 0px;
  }

  .dropdown-submenu:hover>.dropdown-menu {
    display: block;
  }
}

@media (max-width: 1023px) {
  .d-flex {
    display: block !important;
  }

  .d-flex.no-block {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .wrap-feature30-box {
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 50%;
  }

  .wrap-feature31-box .right-image,
  .contact4 .right-image,
  .form6 .right-image {
    position: relative;
    bottom: -95px;
  }

  .wrap-feature31-box .right-image img,
  .contact4 .right-image img,
  .form6 .right-image img {
    width: 100%;
  }

  .contact1 .detail-box {
    margin-top: 40px;
  }

  .contact1 .p-r-40 {
    padding-right: 0;
  }

  .contact4 .contact-box {
    padding: 40px 0px 40px 0px;
  }

  .contact3 .c-detail {
    text-align: center;
  }

  .contact3 .c-detail .m-r-20 {
    margin-right: 0;
  }

  .contact3 .c-detail h6 {
    margin-top: 20px;
  }

  .contact3 .m-l-30 {
    margin-left: 0;
  }

  .contact3 .contact-box {
    margin-top: 30px;
  }

  .wrap-feature34-box .left-image {
    position: relative;
    top: -90px;
  }

  .po-absolute {
    position: relative;
  }

  .contact2 .bg-image {
    margin-right: 15px;
    margin-left: 15px;
  }

  .topbar {
    background: #019e7d;
    position: relative;
  }

  .topbar.animated {
    -webkit-animation-duration: 0s;
    animation-duration: 0s;
  }

  .topbar.animated.slideInDown {
    -webkit-animation-name: none;
    animation-name: none;
  }
}

/*-- ============================================================== 
 Ipad & above all(768px) 
 ============================================================== */
/*-- ============================================================== 
 Phone and below ipad(767px) 
 ============================================================== */
@media (max-width: 767px) {

  /*This is for the Global*/
  .b-l,
  .b-r {
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px solid rgba(120, 130, 140, 0.13);
  }

  .img-inline li.half-width {
    width: 100%;
  }

  .both-space {
    margin: 10px 0;
  }

  .side-content {
    padding: 40px 20px;
  }

  .general-listing.two-part li {
    width: 100%;
    display: block;
  }

  /*This is for the feature 12*/
  .wrap-feature-12 .uneven-box {
    margin-top: 0px;
  }

  /*This is for the feature 16*/
  .wrap-feature-16 .with-text {
    margin-left: 0px;
  }

  /*This is for the feature 18*/
  .wrap-feature-18 .icon-position .icon-round {
    top: 157px;
    right: 30px;
  }

  /*This is for the feature 22*/
  .wrap-feature-22 .text-box,
  .wrap-feature23-box .text-box {
    padding: 20px 0px;
  }

  .wrap-feature30-box {
    position: relative;
  }

  .contact3 .p-l-0 {
    padding-left: 20px;
  }

  .contact4 .contact-box {
    padding-left: 15px;
    padding-right: 15px;
  }

  .modal2 .modal-bg {
    padding: 30px;
  }

  .modal3 .modal-bg {
    padding: 50px 40px 60px;
  }

  .modal4 .modal-bg {
    padding: 40px;
  }

  .dropdown-submenu>.dropdown-menu.show {
    display: block;
  }
}
[data-novi-id="2"] {
  color: #3e4555;
}

/*--------------------------------------------------------------
14.0 Comments
--------------------------------------------------------------*/

#comments {
  clear: both;
  padding: 2em 0 0.5em;
}

.comments-title {
  font-size: 20px;
  font-size: 1.25rem;
  margin-bottom: 1.5em;
}

.comment-list,
.comment-list .children {
  list-style: none;
  margin: 0;
  padding: 0;
}

.comment-list li:before {
  display: none;
}

.comment-body {
  margin-left: 65px;
  margin-right: 65px;
}

.comment-author {
  font-size: 16px;
  font-size: 1rem;
  margin-bottom: 0.4em;
  position: relative;
  z-index: 2;
}

.comment-author .avatar {
  height: 50px;
  left: -65px;
  position: absolute;
  width: 50px;
}

.comment-author .says {
  display: none;
}

.comment-meta {
  margin-bottom: 1.5em;
}

.comment-metadata {
  color: #767676;
  font-size: 10px;
  font-size: 0.625rem;
  font-weight: 800;
  letter-spacing: 0.1818em;
  text-transform: uppercase;
}

.comment-metadata a {
  color: #767676;
}

.comment-metadata a.comment-edit-link {
  color: #222;
  margin-left: 1em;
}

.comment-body {
  color: #333;
  font-size: 14px;
  font-size: 0.875rem;
  margin-bottom: 4em;
}

.comment-reply-link {
  font-weight: 800;
  position: relative;
}

.comment-reply-link .icon {
  color: #222;
  left: -2em;
  height: 1em;
  position: absolute;
  top: 0;
  width: 1em;
}

.children .comment-author .avatar {
  height: 30px;
  left: -45px;
  width: 30px;
}

.bypostauthor > .comment-body > .comment-meta > .comment-author .avatar {
  border: 1px solid #333;
  padding: 2px;
}

.no-comments,
.comment-awaiting-moderation {
  color: #767676;
  font-size: 14px;
  font-size: 0.875rem;
  font-style: italic;
}

.comments-pagination {
  margin: 2em 0 3em;
}

.form-submit {
  text-align: right;
}

.comment-form #wp-comment-cookies-consent {
  margin: 0 10px 0 0;
}

.comment-form .comment-form-cookies-consent label {
  display: inline;
}

.comment-form label {
      color: #333;
    display: block;
    font-weight: 800;
    margin-bottom: 0.5em;
}
.comment-form input[type="text"], input[type="email"], input[type="url"], input[type="password"], input[type="search"], input[type="number"], input[type="tel"], input[type="range"], input[type="date"], input[type="month"], input[type="week"], input[type="time"], input[type="datetime"], input[type="datetime-local"], input[type="color"], textarea {
   color: #666;
    background: #fff;
    background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
    border: 1px solid #bbb;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    display: block;
    padding: 0.7em;
    width: 100%;

        font-size: 1rem;
    line-height: 1.5;
}
.comment-form button, input[type="button"], input[type="submit"] {
    background-color: #222;
    border: 0;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 800;
    line-height: 1;
    padding: 1em 2em;
    text-shadow: none;
    -webkit-transition: background 0.2s;
    transition: background 0.2s;
}
#cancel-comment-reply-link {
      margin-left: 15px;
    font-size: 14px;
}


/* Entry footer */

.entry-footer {
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
  margin-top: 2em;
  padding: 2em 0;
}

.entry-footer .cat-links,
.entry-footer .tags-links {
  display: block;
  font-size: 11px;
  font-size: 0.6875rem;
  font-weight: 800;
  letter-spacing: 0.1818em;
  padding-left: 2.5em;
  position: relative;
  text-transform: uppercase;
}

.entry-footer .cat-links + .tags-links {
  margin-top: 1em;
}

.entry-footer .cat-links a,
.entry-footer .tags-links a {
  color: #333;
}

.entry-footer .cat-links .icon,
.entry-footer .tags-links .icon {
  color: #767676;
  left: 0;
  margin-right: 0.5em;
  position: absolute;
  top: 2px;
}

.entry-footer .edit-link {
  display: inline-block;
}

.entry-footer .edit-link a.post-edit-link {
  background-color: #222;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 800;
  margin-top: 2em;
  padding: 0.7em 2em;
  -webkit-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
  white-space: nowrap;
}

.entry-footer .edit-link a.post-edit-link:hover,
.entry-footer .edit-link a.post-edit-link:focus {
  background-color: #767676;
}

.entry-footer .icon {
      display: inline-block;
    fill: currentColor;
    height: 1em;
    position: relative;
    top: -0.0625em;
    vertical-align: middle;
    width: 1em;
}
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
    word-wrap: normal !important;
}