.bs-slider{
    overflow: hidden;
    position: relative;
}
.bs-slider .bs-slider-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.40);
}
.bs-slider > .carousel-inner > .item > img,
.bs-slider > .carousel-inner > .item > a > img {
    margin: auto;
    width: 100% !important;
}

/********************
*****Slide effect
**********************/

.fade {
    opacity: 1;
}
.fade .item {
    top: 0;
    z-index: 1;
    opacity: 0;
    width: 100%;
    position: absolute;
    left: 0 !important;
    display: block !important;
    -webkit-transition: opacity ease-in-out 1s;
    -moz-transition: opacity ease-in-out 1s;
    -ms-transition: opacity ease-in-out 1s;
    -o-transition: opacity ease-in-out 1s;
    transition: opacity ease-in-out 1s;
}
.fade .item:first-child {
    top: auto;
    position: relative;
}
.fade .item.active {
    opacity: 1;
    z-index: 2;
    -webkit-transition: opacity ease-in-out 1s;
    -moz-transition: opacity ease-in-out 1s;
    -ms-transition: opacity ease-in-out 1s;
    -o-transition: opacity ease-in-out 1s;
    transition: opacity ease-in-out 1s;
}

/*---------- SLIDE CAPTION ----------*/
.slide_style_left {
    text-align: left !important;
}
.slide_style_right {
    text-align: right !important;
}
.slide_style_center {
    text-align: center !important;
}
.slide-text {
    margin: auto;
    position: absolute;
    text-align: left;
    max-width:1170px;
    padding:0 15px;
    top:50%;
    left:50%;
    right:auto;
    width:100%;
    transform:translate(-50%, -50%);
    -webkit-transform:translate(-50%, -50%);
    -moz-transform:translate(-50%, -50%);
}
.slide-image {
		margin: auto;
		width: 100%;
		-webkit-animation-delay: 0.3s;
		animation-delay: 0.3s;
	}
.carousel-control-next {
    width:auto;
    padding-right:15px;
}
.carousel-control-prev {
    width:auto;
    padding-left:15px;
}