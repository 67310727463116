@import url(./components/bootstrap.css);
@import url(./components/aos.css);
@import url(./components/owl.theme.green.css);
@import url(./components/lightgallery.css);
@import url(./components/bootstrap-touch-slider.css);
@import url(./components/fonts/materialdesignicons/material-design-icons.css);
@import url(./components/fonts/font-awesome-5-brands/fa-brands.css);
@import url(./components/novi.css);
@import url(./components/theme.css);
.img-ho {
  overflow: hidden; }

.img-ho img {
  -webkit-transition: 0.1s ease-in;
  -o-transition: 0.1s ease-in;
  transition: 0.1s ease-in; }

.img-ho img:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1); }

.feature8 .invert .col-text {
  order: 2; }

.feature8 .invert .col-img {
  order: 1; }

.feature8 + .feature8 {
  margin-top: -90px; }

section.odd {
  background: #f4f8fa !important; }

section.even {
  background: #fff !important; }

a {
  color: #04d8ab; }
  a:hover {
    text-decoration: underline;
    color: #04d8ab; }

b, strong {
  font-weight: 500; }

.client1 .item {
  text-align: center; }
  .client1 .item .box {
    height: 87px; }
    .client1 .item .box img {
      max-height: 87px;
      margin: 0 auto; }

.client1 .owl-carousel {
  position: relative;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
	                                  supported by Chrome, Edge, Opera and Firefox */ }

.owl-dots {
  margin-top: 40px; }

.owl-theme .owl-nav [class*='owl-'] {
  font: normal normal normal 14px/1 FontAwesome;
  position: absolute;
  top: 50%;
  transform: translateY(calc(-50% - 35px));
  color: #D6D6D6;
  margin: 0;
  padding: 0;
  background: none;
  font-size: 30px;
  transition: all 0.15s ease-in-out; }
  .owl-theme .owl-nav [class*='owl-']:hover {
    color: #019e7d;
    background: none; }
  .owl-theme .owl-nav [class*='owl-'].owl-prev {
    left: -30px; }
    .owl-theme .owl-nav [class*='owl-'].owl-prev:before {
      content: "\f053"; }
  .owl-theme .owl-nav [class*='owl-'].owl-next {
    right: -30px; }
    .owl-theme .owl-nav [class*='owl-'].owl-next:before {
      content: "\f054"; }

#ui-to-top {
  text-decoration: none !important; }

.breadcrumb {
  padding: 30px 0 0 0; }
  .breadcrumb p, .breadcrumb a, .breadcrumb span {
    font-size: 14px; }
  .breadcrumb p {
    margin: 0; }

.breadcrumb-container {
  background: #f4f8fa;
  margin-bottom: -30px; }
  .breadcrumb-container.paginate {
    background: transparent; }

.news-img {
  background-size: cover;
  width: 100%;
  height: 230px;
  position: relative;
  overflow: hidden; }
  .news-img img {
    position: absolute;
    left: -9999px; }

.blog-home1 h5 {
  font-size: 16px; }

.blog-home1 .item {
  margin-bottom: 30px; }

.pager-news span.current {
  color: #FFF !important; }
  .pager-news span.current:hover {
    background: #60b79f !important;
    border: 1px solid #019e7d; }

.pager-news a.btn-secondary:hover {
  background: #60b79f !important;
  border: 1px solid #019e7d; }

.pager-news span.dots {
  background: none;
  border-top: none;
  border-bottom: none;
  box-shadow: none;
  cursor: auto !important;
  padding: 10px; }
  .pager-news span.dots:hover {
    background: !important;
    border-top: none;
    border-bottom: none;
    color: #8d97ad !important; }

.blog-single img {
  max-width: 100%;
  height: auto; }

.wp-block-image {
  margin-bottom: 1.5em; }

.static-slider3.blog {
  padding: 10% 0 5% 0; }

.admin-body #main-wrapper {
  margin-top: 32px; }

.accordion .card-header {
  border-bottom-color: #059e7d; }
  .accordion .card-header h5 {
    font-weight: 500; }
    .accordion .card-header h5 a {
      text-decoration: none;
      display: block; }

.map-box {
  background: #FFF;
  padding: 3em;
  width: 100%;
  height: 100%; }

.blog-single h1 {
  margin: 30px 0; }

.blog-single h2 {
  margin: 30px 0; }

.blog-single h3 {
  margin: 30px 0; }

.navbar-brand .mobile {
  display: none; }
  @media (max-width: 991px) {
    .navbar-brand .mobile {
      display: block; } }

@media (max-width: 991px) {
  .navbar-brand .main {
    display: none; } }
